.is-updated-field {
  border: 1px solid red !important;
  padding: 0.2rem 0.5rem;
  margin-bottom: 0.2rem;
}

.p-padding {
  padding: 0 0.5rem;
  margin-bottom: 0.2rem;
}
.p-capitalize {
  text-transform: capitalize;
}

.text-red {
  color: red;
}
