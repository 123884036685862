.searchbar-advance-filter-collapse.ant-collapse {
  background: transparent;
  border: none;
  > .ant-collapse-item {
    border: none;
    > .ant-collapse-header {
      display: none;
      border: none;
    }
    > .ant-collapse-content {
      border: none;
      > .ant-collapse-content-box {
        border: none;
        padding: 0;
      }
    }
  }
}
@text-selection-bg: #1890FF;