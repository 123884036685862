.align-baseline {
  vertical-align: baseline !important;
}

.align-top {
  vertical-align: top !important;
}

.align-middle {
  vertical-align: middle !important;
}

.align-bottom {
  vertical-align: bottom !important;
}

.align-text-bottom {
  vertical-align: text-bottom !important;
}

.align-text-top {
  vertical-align: text-top !important;
}

.c-dark-theme .bg-primary {
  background-color: #4638c2 !important;
}

.c-dark-theme a.bg-primary:hover,
.c-dark-theme a.bg-primary:focus,
.c-dark-theme button.bg-primary:hover,
.c-dark-theme button.bg-primary:focus {
  background-color: #382d9a !important;
}

.c-dark-theme .bg-secondary {
  background-color: #4c4f54 !important;
}

.c-dark-theme a.bg-secondary:hover,
.c-dark-theme a.bg-secondary:focus,
.c-dark-theme button.bg-secondary:hover,
.c-dark-theme button.bg-secondary:focus {
  background-color: #343639 !important;
}

.c-dark-theme .bg-success {
  background-color: #45a164 !important;
}

.c-dark-theme a.bg-success:hover,
.c-dark-theme a.bg-success:focus,
.c-dark-theme button.bg-success:hover,
.c-dark-theme button.bg-success:focus {
  background-color: #367d4e !important;
}

.c-dark-theme .bg-info {
  background-color: #4799eb !important;
}

.c-dark-theme a.bg-info:hover,
.c-dark-theme a.bg-info:focus,
.c-dark-theme button.bg-info:hover,
.c-dark-theme button.bg-info:focus {
  background-color: #1a80e6 !important;
}

.c-dark-theme .bg-warning {
  background-color: #e1a82d !important;
}

.c-dark-theme a.bg-warning:hover,
.c-dark-theme a.bg-warning:focus,
.c-dark-theme button.bg-warning:hover,
.c-dark-theme button.bg-warning:focus {
  background-color: #c08c1b !important;
}

.c-dark-theme .bg-danger {
  background-color: #d16767 !important;
}

.c-dark-theme a.bg-danger:hover,
.c-dark-theme a.bg-danger:focus,
.c-dark-theme button.bg-danger:hover,
.c-dark-theme button.bg-danger:focus {
  background-color: #c54040 !important;
}

.c-dark-theme .bg-light {
  background-color: #6c6e7e !important;
}

.c-dark-theme a.bg-light:hover,
.c-dark-theme a.bg-light:focus,
.c-dark-theme button.bg-light:hover,
.c-dark-theme button.bg-light:focus {
  background-color: #555662 !important;
}

.c-dark-theme .bg-dark {
  background-color: #0e0e15 !important;
}

.c-dark-theme a.bg-dark:hover,
.c-dark-theme a.bg-dark:focus,
.c-dark-theme button.bg-dark:hover,
.c-dark-theme button.bg-dark:focus {
  background-color: black !important;
}

.c-legacy-theme .bg-primary {
  background-color: #20a8d8 !important;
}

.c-legacy-theme a.bg-primary:hover,
.c-legacy-theme a.bg-primary:focus,
.c-legacy-theme button.bg-primary:hover,
.c-legacy-theme button.bg-primary:focus {
  background-color: #1985ac !important;
}

.c-legacy-theme .bg-secondary {
  background-color: #c8ced3 !important;
}

.c-legacy-theme a.bg-secondary:hover,
.c-legacy-theme a.bg-secondary:focus,
.c-legacy-theme button.bg-secondary:hover,
.c-legacy-theme button.bg-secondary:focus {
  background-color: #acb5bc !important;
}

.c-legacy-theme .bg-success {
  background-color: #4dbd74 !important;
}

.c-legacy-theme a.bg-success:hover,
.c-legacy-theme a.bg-success:focus,
.c-legacy-theme button.bg-success:hover,
.c-legacy-theme button.bg-success:focus {
  background-color: #3a9d5d !important;
}

.c-legacy-theme .bg-info {
  background-color: #63c2de !important;
}

.c-legacy-theme a.bg-info:hover,
.c-legacy-theme a.bg-info:focus,
.c-legacy-theme button.bg-info:hover,
.c-legacy-theme button.bg-info:focus {
  background-color: #39b2d5 !important;
}

.c-legacy-theme .bg-warning {
  background-color: #ffc107 !important;
}

.c-legacy-theme a.bg-warning:hover,
.c-legacy-theme a.bg-warning:focus,
.c-legacy-theme button.bg-warning:hover,
.c-legacy-theme button.bg-warning:focus {
  background-color: #d39e00 !important;
}

.c-legacy-theme .bg-danger {
  background-color: #f86c6b !important;
}

.c-legacy-theme a.bg-danger:hover,
.c-legacy-theme a.bg-danger:focus,
.c-legacy-theme button.bg-danger:hover,
.c-legacy-theme button.bg-danger:focus {
  background-color: #f63c3a !important;
}

.c-legacy-theme .bg-light {
  background-color: #f0f3f5 !important;
}

.c-legacy-theme a.bg-light:hover,
.c-legacy-theme a.bg-light:focus,
.c-legacy-theme button.bg-light:hover,
.c-legacy-theme button.bg-light:focus {
  background-color: #d1dbe1 !important;
}

.c-legacy-theme .bg-dark {
  background-color: #2f353a !important;
}

.c-legacy-theme a.bg-dark:hover,
.c-legacy-theme a.bg-dark:focus,
.c-legacy-theme button.bg-dark:hover,
.c-legacy-theme button.bg-dark:focus {
  background-color: #181b1e !important;
}

.bg-primary {
  background-color: #321fdb !important;
}

a.bg-primary:hover,
a.bg-primary:focus,
button.bg-primary:hover,
button.bg-primary:focus {
  background-color: #2819ae !important;
}

.bg-secondary {
  background-color: #ced2d8 !important;
}

a.bg-secondary:hover,
a.bg-secondary:focus,
button.bg-secondary:hover,
button.bg-secondary:focus {
  background-color: #b2b8c1 !important;
}

.bg-success {
  background-color: #2eb85c !important;
}

a.bg-success:hover,
a.bg-success:focus,
button.bg-success:hover,
button.bg-success:focus {
  background-color: #248f48 !important;
}

.bg-info {
  background-color: #39f !important;
}

a.bg-info:hover,
a.bg-info:focus,
button.bg-info:hover,
button.bg-info:focus {
  background-color: #0080ff !important;
}

.bg-warning {
  background-color: #f9b115 !important;
}

a.bg-warning:hover,
a.bg-warning:focus,
button.bg-warning:hover,
button.bg-warning:focus {
  background-color: #d69405 !important;
}

.bg-danger {
  background-color: #e55353 !important;
}

a.bg-danger:hover,
a.bg-danger:focus,
button.bg-danger:hover,
button.bg-danger:focus {
  background-color: #de2727 !important;
}

.bg-light {
  background-color: #ebedef !important;
}

a.bg-light:hover,
a.bg-light:focus,
button.bg-light:hover,
button.bg-light:focus {
  background-color: #cfd4d8 !important;
}

.bg-dark {
  background-color: #636f83 !important;
}

a.bg-dark:hover,
a.bg-dark:focus,
button.bg-dark:hover,
button.bg-dark:focus {
  background-color: #4d5666 !important;
}

.c-dark-theme .bg-gradient-primary {
  background: #2d2587 !important;
  background: linear-gradient(45deg, #4638c2 0%, #2d2587 100%) !important;
  border-color: #2d2587 !important;
}

.c-dark-theme .bg-gradient-secondary {
  background: white !important;
  background: linear-gradient(45deg, #d1d2d3 0%, white 100%) !important;
  border-color: white !important;
}

.c-dark-theme .bg-gradient-success {
  background: #2e8c47 !important;
  background: linear-gradient(45deg, #45a164 0%, #2e8c47 100%) !important;
  border-color: #2e8c47 !important;
}

.c-dark-theme .bg-gradient-info {
  background: #4280b4 !important;
  background: linear-gradient(45deg, #4799eb 0%, #4280b4 100%) !important;
  border-color: #4280b4 !important;
}

.c-dark-theme .bg-gradient-warning {
  background: #dd9124 !important;
  background: linear-gradient(45deg, #e1a82d 0%, #dd9124 100%) !important;
  border-color: #dd9124 !important;
}

.c-dark-theme .bg-gradient-danger {
  background: #c14f4f !important;
  background: linear-gradient(45deg, #d16767 0%, #c14f4f 100%) !important;
  border-color: #c14f4f !important;
}

.c-dark-theme .bg-gradient-light {
  background: white !important;
  background: linear-gradient(45deg, #e8e8e8 0%, white 100%) !important;
  border-color: white !important;
}

.c-dark-theme .bg-gradient-dark {
  background: #292a2b !important;
  background: linear-gradient(45deg, #4c4f54 0%, #292a2b 100%) !important;
  border-color: #292a2b !important;
}

.c-legacy-theme .bg-gradient-primary {
  background: #1d97c2 !important;
  background: linear-gradient(45deg, #31b3e0 0%, #1d97c2 100%) !important;
  border-color: #1d97c2 !important;
}

.c-legacy-theme .bg-gradient-secondary {
  background: #bac1c8 !important;
  background: linear-gradient(45deg, #d6dbde 0%, #bac1c8 100%) !important;
  border-color: #bac1c8 !important;
}

.c-legacy-theme .bg-gradient-success {
  background: #41af67 !important;
  background: linear-gradient(45deg, #60c483 0%, #41af67 100%) !important;
  border-color: #41af67 !important;
}

.c-legacy-theme .bg-gradient-info {
  background: #4ebada !important;
  background: linear-gradient(45deg, #78cae2 0%, #4ebada 100%) !important;
  border-color: #4ebada !important;
}

.c-legacy-theme .bg-gradient-warning {
  background: #edb100 !important;
  background: linear-gradient(45deg, #ffc721 0%, #edb100 100%) !important;
  border-color: #edb100 !important;
}

.c-legacy-theme .bg-gradient-danger {
  background: #f75453 !important;
  background: linear-gradient(45deg, #f98483 0%, #f75453 100%) !important;
  border-color: #f75453 !important;
}

.c-legacy-theme .bg-gradient-light {
  background: #e1e7eb !important;
  background: linear-gradient(45deg, white 0%, #e1e7eb 100%) !important;
  border-color: #e1e7eb !important;
}

.c-legacy-theme .bg-gradient-dark {
  background: #24282c !important;
  background: linear-gradient(45deg, #3a4248 0%, #24282c 100%) !important;
  border-color: #24282c !important;
}

.bg-gradient-primary {
  background: #1f1498 !important;
  background: linear-gradient(45deg, #321fdb 0%, #1f1498 100%) !important;
  border-color: #1f1498 !important;
}

.bg-gradient-secondary {
  background: #fff !important;
  background: linear-gradient(45deg, #c8d2dc 0%, #fff 100%) !important;
  border-color: #fff !important;
}

.bg-gradient-success {
  background: #1b9e3e !important;
  background: linear-gradient(45deg, #2eb85c 0%, #1b9e3e 100%) !important;
  border-color: #1b9e3e !important;
}

.bg-gradient-info {
  background: #2982cc !important;
  background: linear-gradient(45deg, #39f 0%, #2982cc 100%) !important;
  border-color: #2982cc !important;
}

.bg-gradient-warning {
  background: #f6960b !important;
  background: linear-gradient(45deg, #f9b115 0%, #f6960b 100%) !important;
  border-color: #f6960b !important;
}

.bg-gradient-danger {
  background: #d93737 !important;
  background: linear-gradient(45deg, #e55353 0%, #d93737 100%) !important;
  border-color: #d93737 !important;
}

.bg-gradient-light {
  background: #fff !important;
  background: linear-gradient(45deg, #e3e8ed 0%, #fff 100%) !important;
  border-color: #fff !important;
}

.bg-gradient-dark {
  background: #212333 !important;
  background: linear-gradient(45deg, #3c4b64 0%, #212333 100%) !important;
  border-color: #212333 !important;
}

.bg-white {
  background-color: #fff !important;
}

.bg-transparent {
  background-color: transparent !important;
}

[class^='bg-'] {
  color: #fff;
}

.bg-facebook {
  background-color: #3b5998 !important;
}

a.bg-facebook:hover,
a.bg-facebook:focus,
button.bg-facebook:hover,
button.bg-facebook:focus {
  background-color: #2d4373 !important;
}

.bg-twitter {
  background-color: #00aced !important;
}

a.bg-twitter:hover,
a.bg-twitter:focus,
button.bg-twitter:hover,
button.bg-twitter:focus {
  background-color: #0087ba !important;
}

.bg-linkedin {
  background-color: #4875b4 !important;
}

a.bg-linkedin:hover,
a.bg-linkedin:focus,
button.bg-linkedin:hover,
button.bg-linkedin:focus {
  background-color: #395d90 !important;
}

.bg-flickr {
  background-color: #ff0084 !important;
}

a.bg-flickr:hover,
a.bg-flickr:focus,
button.bg-flickr:hover,
button.bg-flickr:focus {
  background-color: #cc006a !important;
}

.bg-tumblr {
  background-color: #32506d !important;
}

a.bg-tumblr:hover,
a.bg-tumblr:focus,
button.bg-tumblr:hover,
button.bg-tumblr:focus {
  background-color: #22364a !important;
}

.bg-xing {
  background-color: #026466 !important;
}

a.bg-xing:hover,
a.bg-xing:focus,
button.bg-xing:hover,
button.bg-xing:focus {
  background-color: #013334 !important;
}

.bg-github {
  background-color: #4183c4 !important;
}

a.bg-github:hover,
a.bg-github:focus,
button.bg-github:hover,
button.bg-github:focus {
  background-color: #3269a0 !important;
}

.bg-stack-overflow {
  background-color: #fe7a15 !important;
}

a.bg-stack-overflow:hover,
a.bg-stack-overflow:focus,
button.bg-stack-overflow:hover,
button.bg-stack-overflow:focus {
  background-color: #df6101 !important;
}

.bg-youtube {
  background-color: #b00 !important;
}

a.bg-youtube:hover,
a.bg-youtube:focus,
button.bg-youtube:hover,
button.bg-youtube:focus {
  background-color: #880000 !important;
}

.bg-dribbble {
  background-color: #ea4c89 !important;
}

a.bg-dribbble:hover,
a.bg-dribbble:focus,
button.bg-dribbble:hover,
button.bg-dribbble:focus {
  background-color: #e51e6b !important;
}

.bg-instagram {
  background-color: #517fa4 !important;
}

a.bg-instagram:hover,
a.bg-instagram:focus,
button.bg-instagram:hover,
button.bg-instagram:focus {
  background-color: #406582 !important;
}

.bg-pinterest {
  background-color: #cb2027 !important;
}

a.bg-pinterest:hover,
a.bg-pinterest:focus,
button.bg-pinterest:hover,
button.bg-pinterest:focus {
  background-color: #9f191f !important;
}

.bg-vk {
  background-color: #45668e !important;
}

a.bg-vk:hover,
a.bg-vk:focus,
button.bg-vk:hover,
button.bg-vk:focus {
  background-color: #344d6c !important;
}

.bg-yahoo {
  background-color: #400191 !important;
}

a.bg-yahoo:hover,
a.bg-yahoo:focus,
button.bg-yahoo:hover,
button.bg-yahoo:focus {
  background-color: #2a015e !important;
}

.bg-behance {
  background-color: #1769ff !important;
}

a.bg-behance:hover,
a.bg-behance:focus,
button.bg-behance:hover,
button.bg-behance:focus {
  background-color: #0050e3 !important;
}

.bg-reddit {
  background-color: #ff4500 !important;
}

a.bg-reddit:hover,
a.bg-reddit:focus,
button.bg-reddit:hover,
button.bg-reddit:focus {
  background-color: #cc3700 !important;
}

.bg-vimeo {
  background-color: #aad450 !important;
}

a.bg-vimeo:hover,
a.bg-vimeo:focus,
button.bg-vimeo:hover,
button.bg-vimeo:focus {
  background-color: #93c130 !important;
}

.bg-gray-100 {
  background-color: #ebedef !important;
}

a.bg-gray-100:hover,
a.bg-gray-100:focus,
button.bg-gray-100:hover,
button.bg-gray-100:focus {
  background-color: #cfd4d8 !important;
}

.bg-gray-200 {
  background-color: #d8dbe0 !important;
}

a.bg-gray-200:hover,
a.bg-gray-200:focus,
button.bg-gray-200:hover,
button.bg-gray-200:focus {
  background-color: #bcc1c9 !important;
}

.bg-gray-300 {
  background-color: #c4c9d0 !important;
}

a.bg-gray-300:hover,
a.bg-gray-300:focus,
button.bg-gray-300:hover,
button.bg-gray-300:focus {
  background-color: #a8afb9 !important;
}

.bg-gray-400 {
  background-color: #b1b7c1 !important;
}

a.bg-gray-400:hover,
a.bg-gray-400:focus,
button.bg-gray-400:hover,
button.bg-gray-400:focus {
  background-color: #959daa !important;
}

.bg-gray-500 {
  background-color: #9da5b1 !important;
}

a.bg-gray-500:hover,
a.bg-gray-500:focus,
button.bg-gray-500:hover,
button.bg-gray-500:focus {
  background-color: #818b9a !important;
}

.bg-gray-600 {
  background-color: #8a93a2 !important;
}

a.bg-gray-600:hover,
a.bg-gray-600:focus,
button.bg-gray-600:hover,
button.bg-gray-600:focus {
  background-color: #6e798b !important;
}

.bg-gray-700 {
  background-color: #768192 !important;
}

a.bg-gray-700:hover,
a.bg-gray-700:focus,
button.bg-gray-700:hover,
button.bg-gray-700:focus {
  background-color: #5e6877 !important;
}

.bg-gray-800 {
  background-color: #636f83 !important;
}

a.bg-gray-800:hover,
a.bg-gray-800:focus,
button.bg-gray-800:hover,
button.bg-gray-800:focus {
  background-color: #4d5666 !important;
}

.bg-gray-900 {
  background-color: #4f5d73 !important;
}

a.bg-gray-900:hover,
a.bg-gray-900:focus,
button.bg-gray-900:hover,
button.bg-gray-900:focus {
  background-color: #3a4555 !important;
}

.bg-box {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 2.5rem;
  height: 2.5rem;
}

.border {
  border: 1px solid #d8dbe0 !important;
}

.border-top {
  border-top: 1px solid #d8dbe0 !important;
}

.border-right {
  border-right: 1px solid #d8dbe0 !important;
}

.border-bottom {
  border-bottom: 1px solid #d8dbe0 !important;
}

.border-left {
  border-left: 1px solid #d8dbe0 !important;
}

.c-legacy-theme .border {
  border: 1px solid #c8ced3 !important;
}

.c-legacy-theme .border-top {
  border-top: 1px solid #c8ced3 !important;
}

.c-legacy-theme .border-right {
  border-right: 1px solid #c8ced3 !important;
}

.c-legacy-theme .border-bottom {
  border-bottom: 1px solid #c8ced3 !important;
}

.c-legacy-theme .border-left {
  border-left: 1px solid #c8ced3 !important;
}

.c-dark-theme .border {
  border: 1px solid rgba(255, 255, 255, 0.075) !important;
}

.c-dark-theme .border-top {
  border-top: 1px solid rgba(255, 255, 255, 0.075) !important;
}

.c-dark-theme .border-right {
  border-right: 1px solid rgba(255, 255, 255, 0.075) !important;
}

.c-dark-theme .border-bottom {
  border-bottom: 1px solid rgba(255, 255, 255, 0.075) !important;
}

.c-dark-theme .border-left {
  border-left: 1px solid rgba(255, 255, 255, 0.075) !important;
}

.border-0 {
  border: 0 !important;
}

.border-top-0 {
  border-top: 0 !important;
}

.border-right-0 {
  border-right: 0 !important;
}

.border-bottom-0 {
  border-bottom: 0 !important;
}

.border-left-0 {
  border-left: 0 !important;
}

.border-white {
  border-color: #fff !important;
}

.rounded-sm {
  border-radius: 0.2rem !important;
}

.rounded {
  border-radius: 0.25rem !important;
}

.rounded-top {
  border-top-left-radius: 0.25rem !important;
  border-top-right-radius: 0.25rem !important;
}

.rounded-right {
  border-top-right-radius: 0.25rem !important;
  border-bottom-right-radius: 0.25rem !important;
}

.rounded-bottom {
  border-bottom-right-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important;
}

.rounded-left {
  border-top-left-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important;
}

.rounded-lg {
  border-radius: 0.3rem !important;
}

.rounded-circle {
  border-radius: 50% !important;
}

.rounded-pill {
  border-radius: 50rem !important;
}

.rounded-0 {
  border-radius: 0 !important;
}

.b-a-0 {
  border: 0 !important;
}

.b-t-0 {
  border-top: 0 !important;
}

.b-r-0 {
  border-right: 0 !important;
}

.b-b-0 {
  border-bottom: 0 !important;
}

.b-l-0 {
  border-left: 0 !important;
}

.b-a-1 {
  border: 1px solid #d8dbe0;
}

.b-t-1 {
  border-top: 1px solid #d8dbe0;
}

.b-r-1 {
  border-right: 1px solid #d8dbe0;
}

.b-b-1 {
  border-bottom: 1px solid #d8dbe0;
}

.b-l-1 {
  border-left: 1px solid #d8dbe0;
}

.b-a-2 {
  border: 2px solid #d8dbe0;
}

.b-t-2 {
  border-top: 2px solid #d8dbe0;
}

.b-r-2 {
  border-right: 2px solid #d8dbe0;
}

.b-b-2 {
  border-bottom: 2px solid #d8dbe0;
}

.b-l-2 {
  border-left: 2px solid #d8dbe0;
}

.content-center {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
  text-align: center;
}

.clearfix::after {
  display: block;
  clear: both;
  content: '';
}

.d-none {
  display: none !important;
}

.d-inline {
  display: inline !important;
}

.d-inline-block {
  display: inline-block !important;
}

.d-block {
  display: block !important;
}

.d-table {
  display: table !important;
}

.d-table-row {
  display: table-row !important;
}

.d-table-cell {
  display: table-cell !important;
}

.d-flex {
  display: flex !important;
}

.d-inline-flex {
  display: inline-flex !important;
}

@media (min-width: 576px) {
  .d-sm-none {
    display: none !important;
  }
  .d-sm-inline {
    display: inline !important;
  }
  .d-sm-inline-block {
    display: inline-block !important;
  }
  .d-sm-block {
    display: block !important;
  }
  .d-sm-table {
    display: table !important;
  }
  .d-sm-table-row {
    display: table-row !important;
  }
  .d-sm-table-cell {
    display: table-cell !important;
  }
  .d-sm-flex {
    display: flex !important;
  }
  .d-sm-inline-flex {
    display: inline-flex !important;
  }
}

@media (min-width: 768px) {
  .d-md-none {
    display: none !important;
  }
  .d-md-inline {
    display: inline !important;
  }
  .d-md-inline-block {
    display: inline-block !important;
  }
  .d-md-block {
    display: block !important;
  }
  .d-md-table {
    display: table !important;
  }
  .d-md-table-row {
    display: table-row !important;
  }
  .d-md-table-cell {
    display: table-cell !important;
  }
  .d-md-flex {
    display: flex !important;
  }
  .d-md-inline-flex {
    display: inline-flex !important;
  }
}

@media (min-width: 992px) {
  .d-lg-none {
    display: none !important;
  }
  .d-lg-inline {
    display: inline !important;
  }
  .d-lg-inline-block {
    display: inline-block !important;
  }
  .d-lg-block {
    display: block !important;
  }
  .d-lg-table {
    display: table !important;
  }
  .d-lg-table-row {
    display: table-row !important;
  }
  .d-lg-table-cell {
    display: table-cell !important;
  }
  .d-lg-flex {
    display: flex !important;
  }
  .d-lg-inline-flex {
    display: inline-flex !important;
  }
}

@media (min-width: 1200px) {
  .d-xl-none {
    display: none !important;
  }
  .d-xl-inline {
    display: inline !important;
  }
  .d-xl-inline-block {
    display: inline-block !important;
  }
  .d-xl-block {
    display: block !important;
  }
  .d-xl-table {
    display: table !important;
  }
  .d-xl-table-row {
    display: table-row !important;
  }
  .d-xl-table-cell {
    display: table-cell !important;
  }
  .d-xl-flex {
    display: flex !important;
  }
  .d-xl-inline-flex {
    display: inline-flex !important;
  }
}

@media (min-width: 1400px) {
  .d-xxl-none {
    display: none !important;
  }
  .d-xxl-inline {
    display: inline !important;
  }
  .d-xxl-inline-block {
    display: inline-block !important;
  }
  .d-xxl-block {
    display: block !important;
  }
  .d-xxl-table {
    display: table !important;
  }
  .d-xxl-table-row {
    display: table-row !important;
  }
  .d-xxl-table-cell {
    display: table-cell !important;
  }
  .d-xxl-flex {
    display: flex !important;
  }
  .d-xxl-inline-flex {
    display: inline-flex !important;
  }
}

@media (max-width: 576px) {
  .d-down-none {
    display: none !important;
  }
}

@media (max-width: 768px) {
  .d-sm-down-none {
    display: none !important;
  }
}

@media (max-width: 992px) {
  .d-md-down-none {
    display: none !important;
  }
}

@media (max-width: 1200px) {
  .d-lg-down-none {
    display: none !important;
  }
}

@media (max-width: 1400px) {
  .d-xl-down-none {
    display: none !important;
  }
}

.d-xxl-down-none {
  display: none !important;
}

.c-dark-theme .c-d-dark-none {
  display: none !important;
}

.c-legacy-theme .c-d-legacy-none {
  display: none !important;
}

.c-default-theme .c-d-default-none {
  display: none !important;
}

.c-app:not(.c-dark-theme):not(.c-legacy-theme) .c-d-default-none {
  display: none !important;
}

@media print {
  .d-print-none {
    display: none !important;
  }
  .d-print-inline {
    display: inline !important;
  }
  .d-print-inline-block {
    display: inline-block !important;
  }
  .d-print-block {
    display: block !important;
  }
  .d-print-table {
    display: table !important;
  }
  .d-print-table-row {
    display: table-row !important;
  }
  .d-print-table-cell {
    display: table-cell !important;
  }
  .d-print-flex {
    display: flex !important;
  }
  .d-print-inline-flex {
    display: inline-flex !important;
  }
}

.embed-responsive {
  position: relative;
  display: block;
  width: 100%;
  padding: 0;
  overflow: hidden;
}

.embed-responsive::before {
  display: block;
  content: '';
}

.embed-responsive .embed-responsive-item,
.embed-responsive iframe,
.embed-responsive embed,
.embed-responsive object,
.embed-responsive video {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 0;
}

.embed-responsive-21by9::before {
  padding-top: 42.857143%;
}

.embed-responsive-16by9::before {
  padding-top: 56.25%;
}

.embed-responsive-4by3::before {
  padding-top: 75%;
}

.embed-responsive-1by1::before {
  padding-top: 100%;
}

.flex-row {
  flex-direction: row !important;
}

.flex-column {
  flex-direction: column !important;
}

.flex-row-reverse {
  flex-direction: row-reverse !important;
}

.flex-column-reverse {
  flex-direction: column-reverse !important;
}

.flex-wrap {
  flex-wrap: wrap !important;
}

.flex-nowrap {
  flex-wrap: nowrap !important;
}

.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important;
}

.flex-fill {
  flex: 1 1 auto !important;
}

.flex-grow-0 {
  flex-grow: 0 !important;
}

.flex-grow-1 {
  flex-grow: 1 !important;
}

.flex-shrink-0 {
  flex-shrink: 0 !important;
}

.flex-shrink-1 {
  flex-shrink: 1 !important;
}

.justify-content-start {
  justify-content: flex-start !important;
}

.justify-content-end {
  justify-content: flex-end !important;
}

.justify-content-center {
  justify-content: center !important;
}

.justify-content-between {
  justify-content: space-between !important;
}

.justify-content-around {
  justify-content: space-around !important;
}

.align-items-start {
  align-items: flex-start !important;
}

.align-items-end {
  align-items: flex-end !important;
}

.align-items-center {
  align-items: center !important;
}

.align-items-baseline {
  align-items: baseline !important;
}

.align-items-stretch {
  align-items: stretch !important;
}

.align-content-start {
  align-content: flex-start !important;
}

.align-content-end {
  align-content: flex-end !important;
}

.align-content-center {
  align-content: center !important;
}

.align-content-between {
  align-content: space-between !important;
}

.align-content-around {
  align-content: space-around !important;
}

.align-content-stretch {
  align-content: stretch !important;
}

.align-self-auto {
  align-self: auto !important;
}

.align-self-start {
  align-self: flex-start !important;
}

.align-self-end {
  align-self: flex-end !important;
}

.align-self-center {
  align-self: center !important;
}

.align-self-baseline {
  align-self: baseline !important;
}

.align-self-stretch {
  align-self: stretch !important;
}

@media (min-width: 576px) {
  .flex-sm-row {
    flex-direction: row !important;
  }
  .flex-sm-column {
    flex-direction: column !important;
  }
  .flex-sm-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-sm-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-sm-wrap {
    flex-wrap: wrap !important;
  }
  .flex-sm-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .flex-sm-fill {
    flex: 1 1 auto !important;
  }
  .flex-sm-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-sm-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-sm-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-sm-shrink-1 {
    flex-shrink: 1 !important;
  }
  .justify-content-sm-start {
    justify-content: flex-start !important;
  }
  .justify-content-sm-end {
    justify-content: flex-end !important;
  }
  .justify-content-sm-center {
    justify-content: center !important;
  }
  .justify-content-sm-between {
    justify-content: space-between !important;
  }
  .justify-content-sm-around {
    justify-content: space-around !important;
  }
  .align-items-sm-start {
    align-items: flex-start !important;
  }
  .align-items-sm-end {
    align-items: flex-end !important;
  }
  .align-items-sm-center {
    align-items: center !important;
  }
  .align-items-sm-baseline {
    align-items: baseline !important;
  }
  .align-items-sm-stretch {
    align-items: stretch !important;
  }
  .align-content-sm-start {
    align-content: flex-start !important;
  }
  .align-content-sm-end {
    align-content: flex-end !important;
  }
  .align-content-sm-center {
    align-content: center !important;
  }
  .align-content-sm-between {
    align-content: space-between !important;
  }
  .align-content-sm-around {
    align-content: space-around !important;
  }
  .align-content-sm-stretch {
    align-content: stretch !important;
  }
  .align-self-sm-auto {
    align-self: auto !important;
  }
  .align-self-sm-start {
    align-self: flex-start !important;
  }
  .align-self-sm-end {
    align-self: flex-end !important;
  }
  .align-self-sm-center {
    align-self: center !important;
  }
  .align-self-sm-baseline {
    align-self: baseline !important;
  }
  .align-self-sm-stretch {
    align-self: stretch !important;
  }
}

@media (min-width: 768px) {
  .flex-md-row {
    flex-direction: row !important;
  }
  .flex-md-column {
    flex-direction: column !important;
  }
  .flex-md-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-md-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-md-wrap {
    flex-wrap: wrap !important;
  }
  .flex-md-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .flex-md-fill {
    flex: 1 1 auto !important;
  }
  .flex-md-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-md-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-md-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-md-shrink-1 {
    flex-shrink: 1 !important;
  }
  .justify-content-md-start {
    justify-content: flex-start !important;
  }
  .justify-content-md-end {
    justify-content: flex-end !important;
  }
  .justify-content-md-center {
    justify-content: center !important;
  }
  .justify-content-md-between {
    justify-content: space-between !important;
  }
  .justify-content-md-around {
    justify-content: space-around !important;
  }
  .align-items-md-start {
    align-items: flex-start !important;
  }
  .align-items-md-end {
    align-items: flex-end !important;
  }
  .align-items-md-center {
    align-items: center !important;
  }
  .align-items-md-baseline {
    align-items: baseline !important;
  }
  .align-items-md-stretch {
    align-items: stretch !important;
  }
  .align-content-md-start {
    align-content: flex-start !important;
  }
  .align-content-md-end {
    align-content: flex-end !important;
  }
  .align-content-md-center {
    align-content: center !important;
  }
  .align-content-md-between {
    align-content: space-between !important;
  }
  .align-content-md-around {
    align-content: space-around !important;
  }
  .align-content-md-stretch {
    align-content: stretch !important;
  }
  .align-self-md-auto {
    align-self: auto !important;
  }
  .align-self-md-start {
    align-self: flex-start !important;
  }
  .align-self-md-end {
    align-self: flex-end !important;
  }
  .align-self-md-center {
    align-self: center !important;
  }
  .align-self-md-baseline {
    align-self: baseline !important;
  }
  .align-self-md-stretch {
    align-self: stretch !important;
  }
}

@media (min-width: 992px) {
  .flex-lg-row {
    flex-direction: row !important;
  }
  .flex-lg-column {
    flex-direction: column !important;
  }
  .flex-lg-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-lg-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-lg-wrap {
    flex-wrap: wrap !important;
  }
  .flex-lg-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .flex-lg-fill {
    flex: 1 1 auto !important;
  }
  .flex-lg-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-lg-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-lg-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-lg-shrink-1 {
    flex-shrink: 1 !important;
  }
  .justify-content-lg-start {
    justify-content: flex-start !important;
  }
  .justify-content-lg-end {
    justify-content: flex-end !important;
  }
  .justify-content-lg-center {
    justify-content: center !important;
  }
  .justify-content-lg-between {
    justify-content: space-between !important;
  }
  .justify-content-lg-around {
    justify-content: space-around !important;
  }
  .align-items-lg-start {
    align-items: flex-start !important;
  }
  .align-items-lg-end {
    align-items: flex-end !important;
  }
  .align-items-lg-center {
    align-items: center !important;
  }
  .align-items-lg-baseline {
    align-items: baseline !important;
  }
  .align-items-lg-stretch {
    align-items: stretch !important;
  }
  .align-content-lg-start {
    align-content: flex-start !important;
  }
  .align-content-lg-end {
    align-content: flex-end !important;
  }
  .align-content-lg-center {
    align-content: center !important;
  }
  .align-content-lg-between {
    align-content: space-between !important;
  }
  .align-content-lg-around {
    align-content: space-around !important;
  }
  .align-content-lg-stretch {
    align-content: stretch !important;
  }
  .align-self-lg-auto {
    align-self: auto !important;
  }
  .align-self-lg-start {
    align-self: flex-start !important;
  }
  .align-self-lg-end {
    align-self: flex-end !important;
  }
  .align-self-lg-center {
    align-self: center !important;
  }
  .align-self-lg-baseline {
    align-self: baseline !important;
  }
  .align-self-lg-stretch {
    align-self: stretch !important;
  }
}

@media (min-width: 1200px) {
  .flex-xl-row {
    flex-direction: row !important;
  }
  .flex-xl-column {
    flex-direction: column !important;
  }
  .flex-xl-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-xl-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-xl-wrap {
    flex-wrap: wrap !important;
  }
  .flex-xl-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .flex-xl-fill {
    flex: 1 1 auto !important;
  }
  .flex-xl-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-xl-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-xl-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-xl-shrink-1 {
    flex-shrink: 1 !important;
  }
  .justify-content-xl-start {
    justify-content: flex-start !important;
  }
  .justify-content-xl-end {
    justify-content: flex-end !important;
  }
  .justify-content-xl-center {
    justify-content: center !important;
  }
  .justify-content-xl-between {
    justify-content: space-between !important;
  }
  .justify-content-xl-around {
    justify-content: space-around !important;
  }
  .align-items-xl-start {
    align-items: flex-start !important;
  }
  .align-items-xl-end {
    align-items: flex-end !important;
  }
  .align-items-xl-center {
    align-items: center !important;
  }
  .align-items-xl-baseline {
    align-items: baseline !important;
  }
  .align-items-xl-stretch {
    align-items: stretch !important;
  }
  .align-content-xl-start {
    align-content: flex-start !important;
  }
  .align-content-xl-end {
    align-content: flex-end !important;
  }
  .align-content-xl-center {
    align-content: center !important;
  }
  .align-content-xl-between {
    align-content: space-between !important;
  }
  .align-content-xl-around {
    align-content: space-around !important;
  }
  .align-content-xl-stretch {
    align-content: stretch !important;
  }
  .align-self-xl-auto {
    align-self: auto !important;
  }
  .align-self-xl-start {
    align-self: flex-start !important;
  }
  .align-self-xl-end {
    align-self: flex-end !important;
  }
  .align-self-xl-center {
    align-self: center !important;
  }
  .align-self-xl-baseline {
    align-self: baseline !important;
  }
  .align-self-xl-stretch {
    align-self: stretch !important;
  }
}

@media (min-width: 1400px) {
  .flex-xxl-row {
    flex-direction: row !important;
  }
  .flex-xxl-column {
    flex-direction: column !important;
  }
  .flex-xxl-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-xxl-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-xxl-wrap {
    flex-wrap: wrap !important;
  }
  .flex-xxl-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-xxl-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .flex-xxl-fill {
    flex: 1 1 auto !important;
  }
  .flex-xxl-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-xxl-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-xxl-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-xxl-shrink-1 {
    flex-shrink: 1 !important;
  }
  .justify-content-xxl-start {
    justify-content: flex-start !important;
  }
  .justify-content-xxl-end {
    justify-content: flex-end !important;
  }
  .justify-content-xxl-center {
    justify-content: center !important;
  }
  .justify-content-xxl-between {
    justify-content: space-between !important;
  }
  .justify-content-xxl-around {
    justify-content: space-around !important;
  }
  .align-items-xxl-start {
    align-items: flex-start !important;
  }
  .align-items-xxl-end {
    align-items: flex-end !important;
  }
  .align-items-xxl-center {
    align-items: center !important;
  }
  .align-items-xxl-baseline {
    align-items: baseline !important;
  }
  .align-items-xxl-stretch {
    align-items: stretch !important;
  }
  .align-content-xxl-start {
    align-content: flex-start !important;
  }
  .align-content-xxl-end {
    align-content: flex-end !important;
  }
  .align-content-xxl-center {
    align-content: center !important;
  }
  .align-content-xxl-between {
    align-content: space-between !important;
  }
  .align-content-xxl-around {
    align-content: space-around !important;
  }
  .align-content-xxl-stretch {
    align-content: stretch !important;
  }
  .align-self-xxl-auto {
    align-self: auto !important;
  }
  .align-self-xxl-start {
    align-self: flex-start !important;
  }
  .align-self-xxl-end {
    align-self: flex-end !important;
  }
  .align-self-xxl-center {
    align-self: center !important;
  }
  .align-self-xxl-baseline {
    align-self: baseline !important;
  }
  .align-self-xxl-stretch {
    align-self: stretch !important;
  }
}

html:not([dir='rtl']) .float-left {
  float: left !important;
}

*[dir='rtl'] .float-left {
  float: right !important;
}

html:not([dir='rtl']) .float-right {
  float: right !important;
}

*[dir='rtl'] .float-right {
  float: left !important;
}

.float-none {
  float: none !important;
}

@media (min-width: 576px) {
  html:not([dir='rtl']) .float-sm-left {
    float: left !important;
  }
  *[dir='rtl'] .float-sm-left {
    float: right !important;
  }
  html:not([dir='rtl']) .float-sm-right {
    float: right !important;
  }
  *[dir='rtl'] .float-sm-right {
    float: left !important;
  }
  .float-sm-none {
    float: none !important;
  }
}

@media (min-width: 768px) {
  html:not([dir='rtl']) .float-md-left {
    float: left !important;
  }
  *[dir='rtl'] .float-md-left {
    float: right !important;
  }
  html:not([dir='rtl']) .float-md-right {
    float: right !important;
  }
  *[dir='rtl'] .float-md-right {
    float: left !important;
  }
  .float-md-none {
    float: none !important;
  }
}

@media (min-width: 992px) {
  html:not([dir='rtl']) .float-lg-left {
    float: left !important;
  }
  *[dir='rtl'] .float-lg-left {
    float: right !important;
  }
  html:not([dir='rtl']) .float-lg-right {
    float: right !important;
  }
  *[dir='rtl'] .float-lg-right {
    float: left !important;
  }
  .float-lg-none {
    float: none !important;
  }
}

@media (min-width: 1200px) {
  html:not([dir='rtl']) .float-xl-left {
    float: left !important;
  }
  *[dir='rtl'] .float-xl-left {
    float: right !important;
  }
  html:not([dir='rtl']) .float-xl-right {
    float: right !important;
  }
  *[dir='rtl'] .float-xl-right {
    float: left !important;
  }
  .float-xl-none {
    float: none !important;
  }
}

@media (min-width: 1400px) {
  html:not([dir='rtl']) .float-xxl-left {
    float: left !important;
  }
  *[dir='rtl'] .float-xxl-left {
    float: right !important;
  }
  html:not([dir='rtl']) .float-xxl-right {
    float: right !important;
  }
  *[dir='rtl'] .float-xxl-right {
    float: left !important;
  }
  .float-xxl-none {
    float: none !important;
  }
}

.user-select-all {
  user-select: all !important;
}

.user-select-auto {
  user-select: auto !important;
}

.user-select-none {
  user-select: none !important;
}

.overflow-auto {
  overflow: auto !important;
}

.overflow-hidden {
  overflow: hidden !important;
}

.position-static {
  position: static !important;
}

.position-relative {
  position: relative !important;
}

.position-absolute {
  position: absolute !important;
}

.position-fixed {
  position: fixed !important;
}

.position-sticky {
  position: sticky !important;
}

.fixed-top {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030;
}

.fixed-bottom {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1030;
}

@supports (position: sticky) {
  .sticky-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }
}

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0;
}

.sr-only-focusable:active,
.sr-only-focusable:focus {
  position: static;
  width: auto;
  height: auto;
  overflow: visible;
  clip: auto;
  white-space: normal;
}

.shadow-sm {
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 21, 0.075) !important;
}

.shadow {
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 21, 0.15) !important;
}

.shadow-lg {
  box-shadow: 0 1rem 3rem rgba(0, 0, 21, 0.175) !important;
}

.shadow-none {
  box-shadow: none !important;
}

.w-25 {
  width: 25% !important;
}

.w-50 {
  width: 50% !important;
}

.w-75 {
  width: 75% !important;
}

.w-100 {
  width: 100% !important;
}

.w-auto {
  width: auto !important;
}

.h-25 {
  height: 25% !important;
}

.h-50 {
  height: 50% !important;
}

.h-75 {
  height: 75% !important;
}

.h-100 {
  height: 100% !important;
}

.h-auto {
  height: auto !important;
}

.mw-100 {
  max-width: 100% !important;
}

.mh-100 {
  max-height: 100% !important;
}

.min-vw-100 {
  min-width: 100vw !important;
}

.min-vh-100 {
  min-height: 100vh !important;
}

.vw-100 {
  width: 100vw !important;
}

.vh-100 {
  height: 100vh !important;
}

.m-0 {
  margin: 0 !important;
}

.mt-0,
.my-0 {
  margin-top: 0 !important;
}

.mr-0,
.mx-0 {
  margin-right: 0 !important;
}

.mb-0,
.my-0 {
  margin-bottom: 0 !important;
}

.ml-0,
.mx-0 {
  margin-left: 0 !important;
}

html:not([dir='rtl']) .mfs-0 {
  margin-left: 0 !important;
}

*[dir='rtl'] .mfs-0 {
  margin-right: 0 !important;
}

html:not([dir='rtl']) .mfe-0 {
  margin-right: 0 !important;
}

*[dir='rtl'] .mfe-0 {
  margin-left: 0 !important;
}

.m-1 {
  margin: 0.25rem !important;
}

.mt-1,
.my-1 {
  margin-top: 0.25rem !important;
}

.mr-1,
.mx-1 {
  margin-right: 0.25rem !important;
}

.mb-1,
.my-1 {
  margin-bottom: 0.25rem !important;
}

.ml-1,
.mx-1 {
  margin-left: 0.25rem !important;
}

html:not([dir='rtl']) .mfs-1 {
  margin-left: 0.25rem !important;
}

*[dir='rtl'] .mfs-1 {
  margin-right: 0.25rem !important;
}

html:not([dir='rtl']) .mfe-1 {
  margin-right: 0.25rem !important;
}

*[dir='rtl'] .mfe-1 {
  margin-left: 0.25rem !important;
}

.m-2 {
  margin: 0.5rem !important;
}

.mt-2,
.my-2 {
  margin-top: 0.5rem !important;
}

.mr-2,
.mx-2 {
  margin-right: 0.5rem !important;
}

.mb-2,
.my-2 {
  margin-bottom: 0.5rem !important;
}

.ml-2,
.mx-2 {
  margin-left: 0.5rem !important;
}

html:not([dir='rtl']) .mfs-2 {
  margin-left: 0.5rem !important;
}

*[dir='rtl'] .mfs-2 {
  margin-right: 0.5rem !important;
}

html:not([dir='rtl']) .mfe-2 {
  margin-right: 0.5rem !important;
}

*[dir='rtl'] .mfe-2 {
  margin-left: 0.5rem !important;
}

.m-3 {
  margin: 1rem !important;
}

.mt-3,
.my-3 {
  margin-top: 1rem !important;
}

.mr-3,
.mx-3 {
  margin-right: 1rem !important;
}

.mb-3,
.my-3 {
  margin-bottom: 1rem !important;
}

.ml-3,
.mx-3 {
  margin-left: 1rem !important;
}

html:not([dir='rtl']) .mfs-3 {
  margin-left: 1rem !important;
}

*[dir='rtl'] .mfs-3 {
  margin-right: 1rem !important;
}

html:not([dir='rtl']) .mfe-3 {
  margin-right: 1rem !important;
}

*[dir='rtl'] .mfe-3 {
  margin-left: 1rem !important;
}

.m-4 {
  margin: 1.5rem !important;
}

.mt-4,
.my-4 {
  margin-top: 1.5rem !important;
}

.mr-4,
.mx-4 {
  margin-right: 1.5rem !important;
}

.mb-4,
.my-4 {
  margin-bottom: 1.5rem !important;
}

.ml-4,
.mx-4 {
  margin-left: 1.5rem !important;
}

html:not([dir='rtl']) .mfs-4 {
  margin-left: 1.5rem !important;
}

*[dir='rtl'] .mfs-4 {
  margin-right: 1.5rem !important;
}

html:not([dir='rtl']) .mfe-4 {
  margin-right: 1.5rem !important;
}

*[dir='rtl'] .mfe-4 {
  margin-left: 1.5rem !important;
}

.m-5 {
  margin: 3rem !important;
}

.mt-5,
.my-5 {
  margin-top: 3rem !important;
}

.mr-5,
.mx-5 {
  margin-right: 3rem !important;
}

.mb-5,
.my-5 {
  margin-bottom: 3rem !important;
}

.ml-5,
.mx-5 {
  margin-left: 3rem !important;
}

html:not([dir='rtl']) .mfs-5 {
  margin-left: 3rem !important;
}

*[dir='rtl'] .mfs-5 {
  margin-right: 3rem !important;
}

html:not([dir='rtl']) .mfe-5 {
  margin-right: 3rem !important;
}

*[dir='rtl'] .mfe-5 {
  margin-left: 3rem !important;
}

.p-0 {
  padding: 0 !important;
}

.pt-0,
.py-0 {
  padding-top: 0 !important;
}

.pr-0,
.px-0 {
  padding-right: 0 !important;
}

.pb-0,
.py-0 {
  padding-bottom: 0 !important;
}

.pl-0,
.px-0 {
  padding-left: 0 !important;
}

html:not([dir='rtl']) .pfs-0 {
  padding-left: 0 !important;
}

*[dir='rtl'] .pfs-0 {
  padding-right: 0 !important;
}

html:not([dir='rtl']) .pfe-0 {
  padding-right: 0 !important;
}

*[dir='rtl'] .pfe-0 {
  padding-left: 0 !important;
}

.p-1 {
  padding: 0.25rem !important;
}

.pt-1,
.py-1 {
  padding-top: 0.25rem !important;
}

.pr-1,
.px-1 {
  padding-right: 0.25rem !important;
}

.pb-1,
.py-1 {
  padding-bottom: 0.25rem !important;
}

.pl-1,
.px-1 {
  padding-left: 0.25rem !important;
}

html:not([dir='rtl']) .pfs-1 {
  padding-left: 0.25rem !important;
}

*[dir='rtl'] .pfs-1 {
  padding-right: 0.25rem !important;
}

html:not([dir='rtl']) .pfe-1 {
  padding-right: 0.25rem !important;
}

*[dir='rtl'] .pfe-1 {
  padding-left: 0.25rem !important;
}

.p-2 {
  padding: 0.5rem !important;
}

.pt-2,
.py-2 {
  padding-top: 0.5rem !important;
}

.pr-2,
.px-2 {
  padding-right: 0.5rem !important;
}

.pb-2,
.py-2 {
  padding-bottom: 0.5rem !important;
}

.pl-2,
.px-2 {
  padding-left: 0.5rem !important;
}

html:not([dir='rtl']) .pfs-2 {
  padding-left: 0.5rem !important;
}

*[dir='rtl'] .pfs-2 {
  padding-right: 0.5rem !important;
}

html:not([dir='rtl']) .pfe-2 {
  padding-right: 0.5rem !important;
}

*[dir='rtl'] .pfe-2 {
  padding-left: 0.5rem !important;
}

.p-3 {
  padding: 1rem !important;
}

.pt-3,
.py-3 {
  padding-top: 1rem !important;
}

.pr-3,
.px-3 {
  padding-right: 1rem !important;
}

.pb-3,
.py-3 {
  padding-bottom: 1rem !important;
}

.pl-3,
.px-3 {
  padding-left: 1rem !important;
}

html:not([dir='rtl']) .pfs-3 {
  padding-left: 1rem !important;
}

*[dir='rtl'] .pfs-3 {
  padding-right: 1rem !important;
}

html:not([dir='rtl']) .pfe-3 {
  padding-right: 1rem !important;
}

*[dir='rtl'] .pfe-3 {
  padding-left: 1rem !important;
}

.p-4 {
  padding: 1.5rem !important;
}

.pt-4,
.py-4 {
  padding-top: 1.5rem !important;
}

.pr-4,
.px-4 {
  padding-right: 1.5rem !important;
}

.pb-4,
.py-4 {
  padding-bottom: 1.5rem !important;
}

.pl-4,
.px-4 {
  padding-left: 1.5rem !important;
}

html:not([dir='rtl']) .pfs-4 {
  padding-left: 1.5rem !important;
}

*[dir='rtl'] .pfs-4 {
  padding-right: 1.5rem !important;
}

html:not([dir='rtl']) .pfe-4 {
  padding-right: 1.5rem !important;
}

*[dir='rtl'] .pfe-4 {
  padding-left: 1.5rem !important;
}

.p-5 {
  padding: 3rem !important;
}

.pt-5,
.py-5 {
  padding-top: 3rem !important;
}

.pr-5,
.px-5 {
  padding-right: 3rem !important;
}

.pb-5,
.py-5 {
  padding-bottom: 3rem !important;
}

.pl-5,
.px-5 {
  padding-left: 3rem !important;
}

html:not([dir='rtl']) .pfs-5 {
  padding-left: 3rem !important;
}

*[dir='rtl'] .pfs-5 {
  padding-right: 3rem !important;
}

html:not([dir='rtl']) .pfe-5 {
  padding-right: 3rem !important;
}

*[dir='rtl'] .pfe-5 {
  padding-left: 3rem !important;
}

.m-n1 {
  margin: -0.25rem !important;
}

.mt-n1,
.my-n1 {
  margin-top: -0.25rem !important;
}

.mr-n1,
.mx-n1 {
  margin-right: -0.25rem !important;
}

.mb-n1,
.my-n1 {
  margin-bottom: -0.25rem !important;
}

.ml-n1,
.mx-n1 {
  margin-left: -0.25rem !important;
}

html:not([dir='rtl']) .mfs-n1 {
  margin-left: -0.25rem !important;
}

*[dir='rtl'] .mfs-n1 {
  margin-right: -0.25rem !important;
}

html:not([dir='rtl']) .mfe-n1 {
  margin-right: -0.25rem !important;
}

*[dir='rtl'] .mfe-n1 {
  margin-left: -0.25rem !important;
}

.m-n2 {
  margin: -0.5rem !important;
}

.mt-n2,
.my-n2 {
  margin-top: -0.5rem !important;
}

.mr-n2,
.mx-n2 {
  margin-right: -0.5rem !important;
}

.mb-n2,
.my-n2 {
  margin-bottom: -0.5rem !important;
}

.ml-n2,
.mx-n2 {
  margin-left: -0.5rem !important;
}

html:not([dir='rtl']) .mfs-n2 {
  margin-left: -0.5rem !important;
}

*[dir='rtl'] .mfs-n2 {
  margin-right: -0.5rem !important;
}

html:not([dir='rtl']) .mfe-n2 {
  margin-right: -0.5rem !important;
}

*[dir='rtl'] .mfe-n2 {
  margin-left: -0.5rem !important;
}

.m-n3 {
  margin: -1rem !important;
}

.mt-n3,
.my-n3 {
  margin-top: -1rem !important;
}

.mr-n3,
.mx-n3 {
  margin-right: -1rem !important;
}

.mb-n3,
.my-n3 {
  margin-bottom: -1rem !important;
}

.ml-n3,
.mx-n3 {
  margin-left: -1rem !important;
}

html:not([dir='rtl']) .mfs-n3 {
  margin-left: -1rem !important;
}

*[dir='rtl'] .mfs-n3 {
  margin-right: -1rem !important;
}

html:not([dir='rtl']) .mfe-n3 {
  margin-right: -1rem !important;
}

*[dir='rtl'] .mfe-n3 {
  margin-left: -1rem !important;
}

.m-n4 {
  margin: -1.5rem !important;
}

.mt-n4,
.my-n4 {
  margin-top: -1.5rem !important;
}

.mr-n4,
.mx-n4 {
  margin-right: -1.5rem !important;
}

.mb-n4,
.my-n4 {
  margin-bottom: -1.5rem !important;
}

.ml-n4,
.mx-n4 {
  margin-left: -1.5rem !important;
}

html:not([dir='rtl']) .mfs-n4 {
  margin-left: -1.5rem !important;
}

*[dir='rtl'] .mfs-n4 {
  margin-right: -1.5rem !important;
}

html:not([dir='rtl']) .mfe-n4 {
  margin-right: -1.5rem !important;
}

*[dir='rtl'] .mfe-n4 {
  margin-left: -1.5rem !important;
}

.m-n5 {
  margin: -3rem !important;
}

.mt-n5,
.my-n5 {
  margin-top: -3rem !important;
}

.mr-n5,
.mx-n5 {
  margin-right: -3rem !important;
}

.mb-n5,
.my-n5 {
  margin-bottom: -3rem !important;
}

.ml-n5,
.mx-n5 {
  margin-left: -3rem !important;
}

html:not([dir='rtl']) .mfs-n5 {
  margin-left: -3rem !important;
}

*[dir='rtl'] .mfs-n5 {
  margin-right: -3rem !important;
}

html:not([dir='rtl']) .mfe-n5 {
  margin-right: -3rem !important;
}

*[dir='rtl'] .mfe-n5 {
  margin-left: -3rem !important;
}

.m-auto {
  margin: auto !important;
}

.mt-auto,
.my-auto {
  margin-top: auto !important;
}

.mr-auto,
.mx-auto {
  margin-right: auto !important;
}

.mb-auto,
.my-auto {
  margin-bottom: auto !important;
}

.ml-auto,
.mx-auto {
  margin-left: auto !important;
}

html:not([dir='rtl']) .mfs-auto {
  margin-left: auto !important;
}

*[dir='rtl'] .mfs-auto {
  margin-right: auto !important;
}

html:not([dir='rtl']) .mfe-auto {
  margin-right: auto !important;
}

*[dir='rtl'] .mfe-auto {
  margin-left: auto !important;
}

@media (min-width: 576px) {
  .m-sm-0 {
    margin: 0 !important;
  }
  .mt-sm-0,
  .my-sm-0 {
    margin-top: 0 !important;
  }
  .mr-sm-0,
  .mx-sm-0 {
    margin-right: 0 !important;
  }
  .mb-sm-0,
  .my-sm-0 {
    margin-bottom: 0 !important;
  }
  .ml-sm-0,
  .mx-sm-0 {
    margin-left: 0 !important;
  }
  html:not([dir='rtl']) .mfs-sm-0 {
    margin-left: 0 !important;
  }
  *[dir='rtl'] .mfs-sm-0 {
    margin-right: 0 !important;
  }
  html:not([dir='rtl']) .mfe-sm-0 {
    margin-right: 0 !important;
  }
  *[dir='rtl'] .mfe-sm-0 {
    margin-left: 0 !important;
  }
  .m-sm-1 {
    margin: 0.25rem !important;
  }
  .mt-sm-1,
  .my-sm-1 {
    margin-top: 0.25rem !important;
  }
  .mr-sm-1,
  .mx-sm-1 {
    margin-right: 0.25rem !important;
  }
  .mb-sm-1,
  .my-sm-1 {
    margin-bottom: 0.25rem !important;
  }
  .ml-sm-1,
  .mx-sm-1 {
    margin-left: 0.25rem !important;
  }
  html:not([dir='rtl']) .mfs-sm-1 {
    margin-left: 0.25rem !important;
  }
  *[dir='rtl'] .mfs-sm-1 {
    margin-right: 0.25rem !important;
  }
  html:not([dir='rtl']) .mfe-sm-1 {
    margin-right: 0.25rem !important;
  }
  *[dir='rtl'] .mfe-sm-1 {
    margin-left: 0.25rem !important;
  }
  .m-sm-2 {
    margin: 0.5rem !important;
  }
  .mt-sm-2,
  .my-sm-2 {
    margin-top: 0.5rem !important;
  }
  .mr-sm-2,
  .mx-sm-2 {
    margin-right: 0.5rem !important;
  }
  .mb-sm-2,
  .my-sm-2 {
    margin-bottom: 0.5rem !important;
  }
  .ml-sm-2,
  .mx-sm-2 {
    margin-left: 0.5rem !important;
  }
  html:not([dir='rtl']) .mfs-sm-2 {
    margin-left: 0.5rem !important;
  }
  *[dir='rtl'] .mfs-sm-2 {
    margin-right: 0.5rem !important;
  }
  html:not([dir='rtl']) .mfe-sm-2 {
    margin-right: 0.5rem !important;
  }
  *[dir='rtl'] .mfe-sm-2 {
    margin-left: 0.5rem !important;
  }
  .m-sm-3 {
    margin: 1rem !important;
  }
  .mt-sm-3,
  .my-sm-3 {
    margin-top: 1rem !important;
  }
  .mr-sm-3,
  .mx-sm-3 {
    margin-right: 1rem !important;
  }
  .mb-sm-3,
  .my-sm-3 {
    margin-bottom: 1rem !important;
  }
  .ml-sm-3,
  .mx-sm-3 {
    margin-left: 1rem !important;
  }
  html:not([dir='rtl']) .mfs-sm-3 {
    margin-left: 1rem !important;
  }
  *[dir='rtl'] .mfs-sm-3 {
    margin-right: 1rem !important;
  }
  html:not([dir='rtl']) .mfe-sm-3 {
    margin-right: 1rem !important;
  }
  *[dir='rtl'] .mfe-sm-3 {
    margin-left: 1rem !important;
  }
  .m-sm-4 {
    margin: 1.5rem !important;
  }
  .mt-sm-4,
  .my-sm-4 {
    margin-top: 1.5rem !important;
  }
  .mr-sm-4,
  .mx-sm-4 {
    margin-right: 1.5rem !important;
  }
  .mb-sm-4,
  .my-sm-4 {
    margin-bottom: 1.5rem !important;
  }
  .ml-sm-4,
  .mx-sm-4 {
    margin-left: 1.5rem !important;
  }
  html:not([dir='rtl']) .mfs-sm-4 {
    margin-left: 1.5rem !important;
  }
  *[dir='rtl'] .mfs-sm-4 {
    margin-right: 1.5rem !important;
  }
  html:not([dir='rtl']) .mfe-sm-4 {
    margin-right: 1.5rem !important;
  }
  *[dir='rtl'] .mfe-sm-4 {
    margin-left: 1.5rem !important;
  }
  .m-sm-5 {
    margin: 3rem !important;
  }
  .mt-sm-5,
  .my-sm-5 {
    margin-top: 3rem !important;
  }
  .mr-sm-5,
  .mx-sm-5 {
    margin-right: 3rem !important;
  }
  .mb-sm-5,
  .my-sm-5 {
    margin-bottom: 3rem !important;
  }
  .ml-sm-5,
  .mx-sm-5 {
    margin-left: 3rem !important;
  }
  html:not([dir='rtl']) .mfs-sm-5 {
    margin-left: 3rem !important;
  }
  *[dir='rtl'] .mfs-sm-5 {
    margin-right: 3rem !important;
  }
  html:not([dir='rtl']) .mfe-sm-5 {
    margin-right: 3rem !important;
  }
  *[dir='rtl'] .mfe-sm-5 {
    margin-left: 3rem !important;
  }
  .p-sm-0 {
    padding: 0 !important;
  }
  .pt-sm-0,
  .py-sm-0 {
    padding-top: 0 !important;
  }
  .pr-sm-0,
  .px-sm-0 {
    padding-right: 0 !important;
  }
  .pb-sm-0,
  .py-sm-0 {
    padding-bottom: 0 !important;
  }
  .pl-sm-0,
  .px-sm-0 {
    padding-left: 0 !important;
  }
  html:not([dir='rtl']) .pfs-sm-0 {
    padding-left: 0 !important;
  }
  *[dir='rtl'] .pfs-sm-0 {
    padding-right: 0 !important;
  }
  html:not([dir='rtl']) .pfe-sm-0 {
    padding-right: 0 !important;
  }
  *[dir='rtl'] .pfe-sm-0 {
    padding-left: 0 !important;
  }
  .p-sm-1 {
    padding: 0.25rem !important;
  }
  .pt-sm-1,
  .py-sm-1 {
    padding-top: 0.25rem !important;
  }
  .pr-sm-1,
  .px-sm-1 {
    padding-right: 0.25rem !important;
  }
  .pb-sm-1,
  .py-sm-1 {
    padding-bottom: 0.25rem !important;
  }
  .pl-sm-1,
  .px-sm-1 {
    padding-left: 0.25rem !important;
  }
  html:not([dir='rtl']) .pfs-sm-1 {
    padding-left: 0.25rem !important;
  }
  *[dir='rtl'] .pfs-sm-1 {
    padding-right: 0.25rem !important;
  }
  html:not([dir='rtl']) .pfe-sm-1 {
    padding-right: 0.25rem !important;
  }
  *[dir='rtl'] .pfe-sm-1 {
    padding-left: 0.25rem !important;
  }
  .p-sm-2 {
    padding: 0.5rem !important;
  }
  .pt-sm-2,
  .py-sm-2 {
    padding-top: 0.5rem !important;
  }
  .pr-sm-2,
  .px-sm-2 {
    padding-right: 0.5rem !important;
  }
  .pb-sm-2,
  .py-sm-2 {
    padding-bottom: 0.5rem !important;
  }
  .pl-sm-2,
  .px-sm-2 {
    padding-left: 0.5rem !important;
  }
  html:not([dir='rtl']) .pfs-sm-2 {
    padding-left: 0.5rem !important;
  }
  *[dir='rtl'] .pfs-sm-2 {
    padding-right: 0.5rem !important;
  }
  html:not([dir='rtl']) .pfe-sm-2 {
    padding-right: 0.5rem !important;
  }
  *[dir='rtl'] .pfe-sm-2 {
    padding-left: 0.5rem !important;
  }
  .p-sm-3 {
    padding: 1rem !important;
  }
  .pt-sm-3,
  .py-sm-3 {
    padding-top: 1rem !important;
  }
  .pr-sm-3,
  .px-sm-3 {
    padding-right: 1rem !important;
  }
  .pb-sm-3,
  .py-sm-3 {
    padding-bottom: 1rem !important;
  }
  .pl-sm-3,
  .px-sm-3 {
    padding-left: 1rem !important;
  }
  html:not([dir='rtl']) .pfs-sm-3 {
    padding-left: 1rem !important;
  }
  *[dir='rtl'] .pfs-sm-3 {
    padding-right: 1rem !important;
  }
  html:not([dir='rtl']) .pfe-sm-3 {
    padding-right: 1rem !important;
  }
  *[dir='rtl'] .pfe-sm-3 {
    padding-left: 1rem !important;
  }
  .p-sm-4 {
    padding: 1.5rem !important;
  }
  .pt-sm-4,
  .py-sm-4 {
    padding-top: 1.5rem !important;
  }
  .pr-sm-4,
  .px-sm-4 {
    padding-right: 1.5rem !important;
  }
  .pb-sm-4,
  .py-sm-4 {
    padding-bottom: 1.5rem !important;
  }
  .pl-sm-4,
  .px-sm-4 {
    padding-left: 1.5rem !important;
  }
  html:not([dir='rtl']) .pfs-sm-4 {
    padding-left: 1.5rem !important;
  }
  *[dir='rtl'] .pfs-sm-4 {
    padding-right: 1.5rem !important;
  }
  html:not([dir='rtl']) .pfe-sm-4 {
    padding-right: 1.5rem !important;
  }
  *[dir='rtl'] .pfe-sm-4 {
    padding-left: 1.5rem !important;
  }
  .p-sm-5 {
    padding: 3rem !important;
  }
  .pt-sm-5,
  .py-sm-5 {
    padding-top: 3rem !important;
  }
  .pr-sm-5,
  .px-sm-5 {
    padding-right: 3rem !important;
  }
  .pb-sm-5,
  .py-sm-5 {
    padding-bottom: 3rem !important;
  }
  .pl-sm-5,
  .px-sm-5 {
    padding-left: 3rem !important;
  }
  html:not([dir='rtl']) .pfs-sm-5 {
    padding-left: 3rem !important;
  }
  *[dir='rtl'] .pfs-sm-5 {
    padding-right: 3rem !important;
  }
  html:not([dir='rtl']) .pfe-sm-5 {
    padding-right: 3rem !important;
  }
  *[dir='rtl'] .pfe-sm-5 {
    padding-left: 3rem !important;
  }
  .m-sm-n1 {
    margin: -0.25rem !important;
  }
  .mt-sm-n1,
  .my-sm-n1 {
    margin-top: -0.25rem !important;
  }
  .mr-sm-n1,
  .mx-sm-n1 {
    margin-right: -0.25rem !important;
  }
  .mb-sm-n1,
  .my-sm-n1 {
    margin-bottom: -0.25rem !important;
  }
  .ml-sm-n1,
  .mx-sm-n1 {
    margin-left: -0.25rem !important;
  }
  html:not([dir='rtl']) .mfs-sm-n1 {
    margin-left: -0.25rem !important;
  }
  *[dir='rtl'] .mfs-sm-n1 {
    margin-right: -0.25rem !important;
  }
  html:not([dir='rtl']) .mfe-sm-n1 {
    margin-right: -0.25rem !important;
  }
  *[dir='rtl'] .mfe-sm-n1 {
    margin-left: -0.25rem !important;
  }
  .m-sm-n2 {
    margin: -0.5rem !important;
  }
  .mt-sm-n2,
  .my-sm-n2 {
    margin-top: -0.5rem !important;
  }
  .mr-sm-n2,
  .mx-sm-n2 {
    margin-right: -0.5rem !important;
  }
  .mb-sm-n2,
  .my-sm-n2 {
    margin-bottom: -0.5rem !important;
  }
  .ml-sm-n2,
  .mx-sm-n2 {
    margin-left: -0.5rem !important;
  }
  html:not([dir='rtl']) .mfs-sm-n2 {
    margin-left: -0.5rem !important;
  }
  *[dir='rtl'] .mfs-sm-n2 {
    margin-right: -0.5rem !important;
  }
  html:not([dir='rtl']) .mfe-sm-n2 {
    margin-right: -0.5rem !important;
  }
  *[dir='rtl'] .mfe-sm-n2 {
    margin-left: -0.5rem !important;
  }
  .m-sm-n3 {
    margin: -1rem !important;
  }
  .mt-sm-n3,
  .my-sm-n3 {
    margin-top: -1rem !important;
  }
  .mr-sm-n3,
  .mx-sm-n3 {
    margin-right: -1rem !important;
  }
  .mb-sm-n3,
  .my-sm-n3 {
    margin-bottom: -1rem !important;
  }
  .ml-sm-n3,
  .mx-sm-n3 {
    margin-left: -1rem !important;
  }
  html:not([dir='rtl']) .mfs-sm-n3 {
    margin-left: -1rem !important;
  }
  *[dir='rtl'] .mfs-sm-n3 {
    margin-right: -1rem !important;
  }
  html:not([dir='rtl']) .mfe-sm-n3 {
    margin-right: -1rem !important;
  }
  *[dir='rtl'] .mfe-sm-n3 {
    margin-left: -1rem !important;
  }
  .m-sm-n4 {
    margin: -1.5rem !important;
  }
  .mt-sm-n4,
  .my-sm-n4 {
    margin-top: -1.5rem !important;
  }
  .mr-sm-n4,
  .mx-sm-n4 {
    margin-right: -1.5rem !important;
  }
  .mb-sm-n4,
  .my-sm-n4 {
    margin-bottom: -1.5rem !important;
  }
  .ml-sm-n4,
  .mx-sm-n4 {
    margin-left: -1.5rem !important;
  }
  html:not([dir='rtl']) .mfs-sm-n4 {
    margin-left: -1.5rem !important;
  }
  *[dir='rtl'] .mfs-sm-n4 {
    margin-right: -1.5rem !important;
  }
  html:not([dir='rtl']) .mfe-sm-n4 {
    margin-right: -1.5rem !important;
  }
  *[dir='rtl'] .mfe-sm-n4 {
    margin-left: -1.5rem !important;
  }
  .m-sm-n5 {
    margin: -3rem !important;
  }
  .mt-sm-n5,
  .my-sm-n5 {
    margin-top: -3rem !important;
  }
  .mr-sm-n5,
  .mx-sm-n5 {
    margin-right: -3rem !important;
  }
  .mb-sm-n5,
  .my-sm-n5 {
    margin-bottom: -3rem !important;
  }
  .ml-sm-n5,
  .mx-sm-n5 {
    margin-left: -3rem !important;
  }
  html:not([dir='rtl']) .mfs-sm-n5 {
    margin-left: -3rem !important;
  }
  *[dir='rtl'] .mfs-sm-n5 {
    margin-right: -3rem !important;
  }
  html:not([dir='rtl']) .mfe-sm-n5 {
    margin-right: -3rem !important;
  }
  *[dir='rtl'] .mfe-sm-n5 {
    margin-left: -3rem !important;
  }
  .m-sm-auto {
    margin: auto !important;
  }
  .mt-sm-auto,
  .my-sm-auto {
    margin-top: auto !important;
  }
  .mr-sm-auto,
  .mx-sm-auto {
    margin-right: auto !important;
  }
  .mb-sm-auto,
  .my-sm-auto {
    margin-bottom: auto !important;
  }
  .ml-sm-auto,
  .mx-sm-auto {
    margin-left: auto !important;
  }
  html:not([dir='rtl']) .mfs-sm-auto {
    margin-left: auto !important;
  }
  *[dir='rtl'] .mfs-sm-auto {
    margin-right: auto !important;
  }
  html:not([dir='rtl']) .mfe-sm-auto {
    margin-right: auto !important;
  }
  *[dir='rtl'] .mfe-sm-auto {
    margin-left: auto !important;
  }
}

@media (min-width: 768px) {
  .m-md-0 {
    margin: 0 !important;
  }
  .mt-md-0,
  .my-md-0 {
    margin-top: 0 !important;
  }
  .mr-md-0,
  .mx-md-0 {
    margin-right: 0 !important;
  }
  .mb-md-0,
  .my-md-0 {
    margin-bottom: 0 !important;
  }
  .ml-md-0,
  .mx-md-0 {
    margin-left: 0 !important;
  }
  html:not([dir='rtl']) .mfs-md-0 {
    margin-left: 0 !important;
  }
  *[dir='rtl'] .mfs-md-0 {
    margin-right: 0 !important;
  }
  html:not([dir='rtl']) .mfe-md-0 {
    margin-right: 0 !important;
  }
  *[dir='rtl'] .mfe-md-0 {
    margin-left: 0 !important;
  }
  .m-md-1 {
    margin: 0.25rem !important;
  }
  .mt-md-1,
  .my-md-1 {
    margin-top: 0.25rem !important;
  }
  .mr-md-1,
  .mx-md-1 {
    margin-right: 0.25rem !important;
  }
  .mb-md-1,
  .my-md-1 {
    margin-bottom: 0.25rem !important;
  }
  .ml-md-1,
  .mx-md-1 {
    margin-left: 0.25rem !important;
  }
  html:not([dir='rtl']) .mfs-md-1 {
    margin-left: 0.25rem !important;
  }
  *[dir='rtl'] .mfs-md-1 {
    margin-right: 0.25rem !important;
  }
  html:not([dir='rtl']) .mfe-md-1 {
    margin-right: 0.25rem !important;
  }
  *[dir='rtl'] .mfe-md-1 {
    margin-left: 0.25rem !important;
  }
  .m-md-2 {
    margin: 0.5rem !important;
  }
  .mt-md-2,
  .my-md-2 {
    margin-top: 0.5rem !important;
  }
  .mr-md-2,
  .mx-md-2 {
    margin-right: 0.5rem !important;
  }
  .mb-md-2,
  .my-md-2 {
    margin-bottom: 0.5rem !important;
  }
  .ml-md-2,
  .mx-md-2 {
    margin-left: 0.5rem !important;
  }
  html:not([dir='rtl']) .mfs-md-2 {
    margin-left: 0.5rem !important;
  }
  *[dir='rtl'] .mfs-md-2 {
    margin-right: 0.5rem !important;
  }
  html:not([dir='rtl']) .mfe-md-2 {
    margin-right: 0.5rem !important;
  }
  *[dir='rtl'] .mfe-md-2 {
    margin-left: 0.5rem !important;
  }
  .m-md-3 {
    margin: 1rem !important;
  }
  .mt-md-3,
  .my-md-3 {
    margin-top: 1rem !important;
  }
  .mr-md-3,
  .mx-md-3 {
    margin-right: 1rem !important;
  }
  .mb-md-3,
  .my-md-3 {
    margin-bottom: 1rem !important;
  }
  .ml-md-3,
  .mx-md-3 {
    margin-left: 1rem !important;
  }
  html:not([dir='rtl']) .mfs-md-3 {
    margin-left: 1rem !important;
  }
  *[dir='rtl'] .mfs-md-3 {
    margin-right: 1rem !important;
  }
  html:not([dir='rtl']) .mfe-md-3 {
    margin-right: 1rem !important;
  }
  *[dir='rtl'] .mfe-md-3 {
    margin-left: 1rem !important;
  }
  .m-md-4 {
    margin: 1.5rem !important;
  }
  .mt-md-4,
  .my-md-4 {
    margin-top: 1.5rem !important;
  }
  .mr-md-4,
  .mx-md-4 {
    margin-right: 1.5rem !important;
  }
  .mb-md-4,
  .my-md-4 {
    margin-bottom: 1.5rem !important;
  }
  .ml-md-4,
  .mx-md-4 {
    margin-left: 1.5rem !important;
  }
  html:not([dir='rtl']) .mfs-md-4 {
    margin-left: 1.5rem !important;
  }
  *[dir='rtl'] .mfs-md-4 {
    margin-right: 1.5rem !important;
  }
  html:not([dir='rtl']) .mfe-md-4 {
    margin-right: 1.5rem !important;
  }
  *[dir='rtl'] .mfe-md-4 {
    margin-left: 1.5rem !important;
  }
  .m-md-5 {
    margin: 3rem !important;
  }
  .mt-md-5,
  .my-md-5 {
    margin-top: 3rem !important;
  }
  .mr-md-5,
  .mx-md-5 {
    margin-right: 3rem !important;
  }
  .mb-md-5,
  .my-md-5 {
    margin-bottom: 3rem !important;
  }
  .ml-md-5,
  .mx-md-5 {
    margin-left: 3rem !important;
  }
  html:not([dir='rtl']) .mfs-md-5 {
    margin-left: 3rem !important;
  }
  *[dir='rtl'] .mfs-md-5 {
    margin-right: 3rem !important;
  }
  html:not([dir='rtl']) .mfe-md-5 {
    margin-right: 3rem !important;
  }
  *[dir='rtl'] .mfe-md-5 {
    margin-left: 3rem !important;
  }
  .p-md-0 {
    padding: 0 !important;
  }
  .pt-md-0,
  .py-md-0 {
    padding-top: 0 !important;
  }
  .pr-md-0,
  .px-md-0 {
    padding-right: 0 !important;
  }
  .pb-md-0,
  .py-md-0 {
    padding-bottom: 0 !important;
  }
  .pl-md-0,
  .px-md-0 {
    padding-left: 0 !important;
  }
  html:not([dir='rtl']) .pfs-md-0 {
    padding-left: 0 !important;
  }
  *[dir='rtl'] .pfs-md-0 {
    padding-right: 0 !important;
  }
  html:not([dir='rtl']) .pfe-md-0 {
    padding-right: 0 !important;
  }
  *[dir='rtl'] .pfe-md-0 {
    padding-left: 0 !important;
  }
  .p-md-1 {
    padding: 0.25rem !important;
  }
  .pt-md-1,
  .py-md-1 {
    padding-top: 0.25rem !important;
  }
  .pr-md-1,
  .px-md-1 {
    padding-right: 0.25rem !important;
  }
  .pb-md-1,
  .py-md-1 {
    padding-bottom: 0.25rem !important;
  }
  .pl-md-1,
  .px-md-1 {
    padding-left: 0.25rem !important;
  }
  html:not([dir='rtl']) .pfs-md-1 {
    padding-left: 0.25rem !important;
  }
  *[dir='rtl'] .pfs-md-1 {
    padding-right: 0.25rem !important;
  }
  html:not([dir='rtl']) .pfe-md-1 {
    padding-right: 0.25rem !important;
  }
  *[dir='rtl'] .pfe-md-1 {
    padding-left: 0.25rem !important;
  }
  .p-md-2 {
    padding: 0.5rem !important;
  }
  .pt-md-2,
  .py-md-2 {
    padding-top: 0.5rem !important;
  }
  .pr-md-2,
  .px-md-2 {
    padding-right: 0.5rem !important;
  }
  .pb-md-2,
  .py-md-2 {
    padding-bottom: 0.5rem !important;
  }
  .pl-md-2,
  .px-md-2 {
    padding-left: 0.5rem !important;
  }
  html:not([dir='rtl']) .pfs-md-2 {
    padding-left: 0.5rem !important;
  }
  *[dir='rtl'] .pfs-md-2 {
    padding-right: 0.5rem !important;
  }
  html:not([dir='rtl']) .pfe-md-2 {
    padding-right: 0.5rem !important;
  }
  *[dir='rtl'] .pfe-md-2 {
    padding-left: 0.5rem !important;
  }
  .p-md-3 {
    padding: 1rem !important;
  }
  .pt-md-3,
  .py-md-3 {
    padding-top: 1rem !important;
  }
  .pr-md-3,
  .px-md-3 {
    padding-right: 1rem !important;
  }
  .pb-md-3,
  .py-md-3 {
    padding-bottom: 1rem !important;
  }
  .pl-md-3,
  .px-md-3 {
    padding-left: 1rem !important;
  }
  html:not([dir='rtl']) .pfs-md-3 {
    padding-left: 1rem !important;
  }
  *[dir='rtl'] .pfs-md-3 {
    padding-right: 1rem !important;
  }
  html:not([dir='rtl']) .pfe-md-3 {
    padding-right: 1rem !important;
  }
  *[dir='rtl'] .pfe-md-3 {
    padding-left: 1rem !important;
  }
  .p-md-4 {
    padding: 1.5rem !important;
  }
  .pt-md-4,
  .py-md-4 {
    padding-top: 1.5rem !important;
  }
  .pr-md-4,
  .px-md-4 {
    padding-right: 1.5rem !important;
  }
  .pb-md-4,
  .py-md-4 {
    padding-bottom: 1.5rem !important;
  }
  .pl-md-4,
  .px-md-4 {
    padding-left: 1.5rem !important;
  }
  html:not([dir='rtl']) .pfs-md-4 {
    padding-left: 1.5rem !important;
  }
  *[dir='rtl'] .pfs-md-4 {
    padding-right: 1.5rem !important;
  }
  html:not([dir='rtl']) .pfe-md-4 {
    padding-right: 1.5rem !important;
  }
  *[dir='rtl'] .pfe-md-4 {
    padding-left: 1.5rem !important;
  }
  .p-md-5 {
    padding: 3rem !important;
  }
  .pt-md-5,
  .py-md-5 {
    padding-top: 3rem !important;
  }
  .pr-md-5,
  .px-md-5 {
    padding-right: 3rem !important;
  }
  .pb-md-5,
  .py-md-5 {
    padding-bottom: 3rem !important;
  }
  .pl-md-5,
  .px-md-5 {
    padding-left: 3rem !important;
  }
  html:not([dir='rtl']) .pfs-md-5 {
    padding-left: 3rem !important;
  }
  *[dir='rtl'] .pfs-md-5 {
    padding-right: 3rem !important;
  }
  html:not([dir='rtl']) .pfe-md-5 {
    padding-right: 3rem !important;
  }
  *[dir='rtl'] .pfe-md-5 {
    padding-left: 3rem !important;
  }
  .m-md-n1 {
    margin: -0.25rem !important;
  }
  .mt-md-n1,
  .my-md-n1 {
    margin-top: -0.25rem !important;
  }
  .mr-md-n1,
  .mx-md-n1 {
    margin-right: -0.25rem !important;
  }
  .mb-md-n1,
  .my-md-n1 {
    margin-bottom: -0.25rem !important;
  }
  .ml-md-n1,
  .mx-md-n1 {
    margin-left: -0.25rem !important;
  }
  html:not([dir='rtl']) .mfs-md-n1 {
    margin-left: -0.25rem !important;
  }
  *[dir='rtl'] .mfs-md-n1 {
    margin-right: -0.25rem !important;
  }
  html:not([dir='rtl']) .mfe-md-n1 {
    margin-right: -0.25rem !important;
  }
  *[dir='rtl'] .mfe-md-n1 {
    margin-left: -0.25rem !important;
  }
  .m-md-n2 {
    margin: -0.5rem !important;
  }
  .mt-md-n2,
  .my-md-n2 {
    margin-top: -0.5rem !important;
  }
  .mr-md-n2,
  .mx-md-n2 {
    margin-right: -0.5rem !important;
  }
  .mb-md-n2,
  .my-md-n2 {
    margin-bottom: -0.5rem !important;
  }
  .ml-md-n2,
  .mx-md-n2 {
    margin-left: -0.5rem !important;
  }
  html:not([dir='rtl']) .mfs-md-n2 {
    margin-left: -0.5rem !important;
  }
  *[dir='rtl'] .mfs-md-n2 {
    margin-right: -0.5rem !important;
  }
  html:not([dir='rtl']) .mfe-md-n2 {
    margin-right: -0.5rem !important;
  }
  *[dir='rtl'] .mfe-md-n2 {
    margin-left: -0.5rem !important;
  }
  .m-md-n3 {
    margin: -1rem !important;
  }
  .mt-md-n3,
  .my-md-n3 {
    margin-top: -1rem !important;
  }
  .mr-md-n3,
  .mx-md-n3 {
    margin-right: -1rem !important;
  }
  .mb-md-n3,
  .my-md-n3 {
    margin-bottom: -1rem !important;
  }
  .ml-md-n3,
  .mx-md-n3 {
    margin-left: -1rem !important;
  }
  html:not([dir='rtl']) .mfs-md-n3 {
    margin-left: -1rem !important;
  }
  *[dir='rtl'] .mfs-md-n3 {
    margin-right: -1rem !important;
  }
  html:not([dir='rtl']) .mfe-md-n3 {
    margin-right: -1rem !important;
  }
  *[dir='rtl'] .mfe-md-n3 {
    margin-left: -1rem !important;
  }
  .m-md-n4 {
    margin: -1.5rem !important;
  }
  .mt-md-n4,
  .my-md-n4 {
    margin-top: -1.5rem !important;
  }
  .mr-md-n4,
  .mx-md-n4 {
    margin-right: -1.5rem !important;
  }
  .mb-md-n4,
  .my-md-n4 {
    margin-bottom: -1.5rem !important;
  }
  .ml-md-n4,
  .mx-md-n4 {
    margin-left: -1.5rem !important;
  }
  html:not([dir='rtl']) .mfs-md-n4 {
    margin-left: -1.5rem !important;
  }
  *[dir='rtl'] .mfs-md-n4 {
    margin-right: -1.5rem !important;
  }
  html:not([dir='rtl']) .mfe-md-n4 {
    margin-right: -1.5rem !important;
  }
  *[dir='rtl'] .mfe-md-n4 {
    margin-left: -1.5rem !important;
  }
  .m-md-n5 {
    margin: -3rem !important;
  }
  .mt-md-n5,
  .my-md-n5 {
    margin-top: -3rem !important;
  }
  .mr-md-n5,
  .mx-md-n5 {
    margin-right: -3rem !important;
  }
  .mb-md-n5,
  .my-md-n5 {
    margin-bottom: -3rem !important;
  }
  .ml-md-n5,
  .mx-md-n5 {
    margin-left: -3rem !important;
  }
  html:not([dir='rtl']) .mfs-md-n5 {
    margin-left: -3rem !important;
  }
  *[dir='rtl'] .mfs-md-n5 {
    margin-right: -3rem !important;
  }
  html:not([dir='rtl']) .mfe-md-n5 {
    margin-right: -3rem !important;
  }
  *[dir='rtl'] .mfe-md-n5 {
    margin-left: -3rem !important;
  }
  .m-md-auto {
    margin: auto !important;
  }
  .mt-md-auto,
  .my-md-auto {
    margin-top: auto !important;
  }
  .mr-md-auto,
  .mx-md-auto {
    margin-right: auto !important;
  }
  .mb-md-auto,
  .my-md-auto {
    margin-bottom: auto !important;
  }
  .ml-md-auto,
  .mx-md-auto {
    margin-left: auto !important;
  }
  html:not([dir='rtl']) .mfs-md-auto {
    margin-left: auto !important;
  }
  *[dir='rtl'] .mfs-md-auto {
    margin-right: auto !important;
  }
  html:not([dir='rtl']) .mfe-md-auto {
    margin-right: auto !important;
  }
  *[dir='rtl'] .mfe-md-auto {
    margin-left: auto !important;
  }
}

@media (min-width: 992px) {
  .m-lg-0 {
    margin: 0 !important;
  }
  .mt-lg-0,
  .my-lg-0 {
    margin-top: 0 !important;
  }
  .mr-lg-0,
  .mx-lg-0 {
    margin-right: 0 !important;
  }
  .mb-lg-0,
  .my-lg-0 {
    margin-bottom: 0 !important;
  }
  .ml-lg-0,
  .mx-lg-0 {
    margin-left: 0 !important;
  }
  html:not([dir='rtl']) .mfs-lg-0 {
    margin-left: 0 !important;
  }
  *[dir='rtl'] .mfs-lg-0 {
    margin-right: 0 !important;
  }
  html:not([dir='rtl']) .mfe-lg-0 {
    margin-right: 0 !important;
  }
  *[dir='rtl'] .mfe-lg-0 {
    margin-left: 0 !important;
  }
  .m-lg-1 {
    margin: 0.25rem !important;
  }
  .mt-lg-1,
  .my-lg-1 {
    margin-top: 0.25rem !important;
  }
  .mr-lg-1,
  .mx-lg-1 {
    margin-right: 0.25rem !important;
  }
  .mb-lg-1,
  .my-lg-1 {
    margin-bottom: 0.25rem !important;
  }
  .ml-lg-1,
  .mx-lg-1 {
    margin-left: 0.25rem !important;
  }
  html:not([dir='rtl']) .mfs-lg-1 {
    margin-left: 0.25rem !important;
  }
  *[dir='rtl'] .mfs-lg-1 {
    margin-right: 0.25rem !important;
  }
  html:not([dir='rtl']) .mfe-lg-1 {
    margin-right: 0.25rem !important;
  }
  *[dir='rtl'] .mfe-lg-1 {
    margin-left: 0.25rem !important;
  }
  .m-lg-2 {
    margin: 0.5rem !important;
  }
  .mt-lg-2,
  .my-lg-2 {
    margin-top: 0.5rem !important;
  }
  .mr-lg-2,
  .mx-lg-2 {
    margin-right: 0.5rem !important;
  }
  .mb-lg-2,
  .my-lg-2 {
    margin-bottom: 0.5rem !important;
  }
  .ml-lg-2,
  .mx-lg-2 {
    margin-left: 0.5rem !important;
  }
  html:not([dir='rtl']) .mfs-lg-2 {
    margin-left: 0.5rem !important;
  }
  *[dir='rtl'] .mfs-lg-2 {
    margin-right: 0.5rem !important;
  }
  html:not([dir='rtl']) .mfe-lg-2 {
    margin-right: 0.5rem !important;
  }
  *[dir='rtl'] .mfe-lg-2 {
    margin-left: 0.5rem !important;
  }
  .m-lg-3 {
    margin: 1rem !important;
  }
  .mt-lg-3,
  .my-lg-3 {
    margin-top: 1rem !important;
  }
  .mr-lg-3,
  .mx-lg-3 {
    margin-right: 1rem !important;
  }
  .mb-lg-3,
  .my-lg-3 {
    margin-bottom: 1rem !important;
  }
  .ml-lg-3,
  .mx-lg-3 {
    margin-left: 1rem !important;
  }
  html:not([dir='rtl']) .mfs-lg-3 {
    margin-left: 1rem !important;
  }
  *[dir='rtl'] .mfs-lg-3 {
    margin-right: 1rem !important;
  }
  html:not([dir='rtl']) .mfe-lg-3 {
    margin-right: 1rem !important;
  }
  *[dir='rtl'] .mfe-lg-3 {
    margin-left: 1rem !important;
  }
  .m-lg-4 {
    margin: 1.5rem !important;
  }
  .mt-lg-4,
  .my-lg-4 {
    margin-top: 1.5rem !important;
  }
  .mr-lg-4,
  .mx-lg-4 {
    margin-right: 1.5rem !important;
  }
  .mb-lg-4,
  .my-lg-4 {
    margin-bottom: 1.5rem !important;
  }
  .ml-lg-4,
  .mx-lg-4 {
    margin-left: 1.5rem !important;
  }
  html:not([dir='rtl']) .mfs-lg-4 {
    margin-left: 1.5rem !important;
  }
  *[dir='rtl'] .mfs-lg-4 {
    margin-right: 1.5rem !important;
  }
  html:not([dir='rtl']) .mfe-lg-4 {
    margin-right: 1.5rem !important;
  }
  *[dir='rtl'] .mfe-lg-4 {
    margin-left: 1.5rem !important;
  }
  .m-lg-5 {
    margin: 3rem !important;
  }
  .mt-lg-5,
  .my-lg-5 {
    margin-top: 3rem !important;
  }
  .mr-lg-5,
  .mx-lg-5 {
    margin-right: 3rem !important;
  }
  .mb-lg-5,
  .my-lg-5 {
    margin-bottom: 3rem !important;
  }
  .ml-lg-5,
  .mx-lg-5 {
    margin-left: 3rem !important;
  }
  html:not([dir='rtl']) .mfs-lg-5 {
    margin-left: 3rem !important;
  }
  *[dir='rtl'] .mfs-lg-5 {
    margin-right: 3rem !important;
  }
  html:not([dir='rtl']) .mfe-lg-5 {
    margin-right: 3rem !important;
  }
  *[dir='rtl'] .mfe-lg-5 {
    margin-left: 3rem !important;
  }
  .p-lg-0 {
    padding: 0 !important;
  }
  .pt-lg-0,
  .py-lg-0 {
    padding-top: 0 !important;
  }
  .pr-lg-0,
  .px-lg-0 {
    padding-right: 0 !important;
  }
  .pb-lg-0,
  .py-lg-0 {
    padding-bottom: 0 !important;
  }
  .pl-lg-0,
  .px-lg-0 {
    padding-left: 0 !important;
  }
  html:not([dir='rtl']) .pfs-lg-0 {
    padding-left: 0 !important;
  }
  *[dir='rtl'] .pfs-lg-0 {
    padding-right: 0 !important;
  }
  html:not([dir='rtl']) .pfe-lg-0 {
    padding-right: 0 !important;
  }
  *[dir='rtl'] .pfe-lg-0 {
    padding-left: 0 !important;
  }
  .p-lg-1 {
    padding: 0.25rem !important;
  }
  .pt-lg-1,
  .py-lg-1 {
    padding-top: 0.25rem !important;
  }
  .pr-lg-1,
  .px-lg-1 {
    padding-right: 0.25rem !important;
  }
  .pb-lg-1,
  .py-lg-1 {
    padding-bottom: 0.25rem !important;
  }
  .pl-lg-1,
  .px-lg-1 {
    padding-left: 0.25rem !important;
  }
  html:not([dir='rtl']) .pfs-lg-1 {
    padding-left: 0.25rem !important;
  }
  *[dir='rtl'] .pfs-lg-1 {
    padding-right: 0.25rem !important;
  }
  html:not([dir='rtl']) .pfe-lg-1 {
    padding-right: 0.25rem !important;
  }
  *[dir='rtl'] .pfe-lg-1 {
    padding-left: 0.25rem !important;
  }
  .p-lg-2 {
    padding: 0.5rem !important;
  }
  .pt-lg-2,
  .py-lg-2 {
    padding-top: 0.5rem !important;
  }
  .pr-lg-2,
  .px-lg-2 {
    padding-right: 0.5rem !important;
  }
  .pb-lg-2,
  .py-lg-2 {
    padding-bottom: 0.5rem !important;
  }
  .pl-lg-2,
  .px-lg-2 {
    padding-left: 0.5rem !important;
  }
  html:not([dir='rtl']) .pfs-lg-2 {
    padding-left: 0.5rem !important;
  }
  *[dir='rtl'] .pfs-lg-2 {
    padding-right: 0.5rem !important;
  }
  html:not([dir='rtl']) .pfe-lg-2 {
    padding-right: 0.5rem !important;
  }
  *[dir='rtl'] .pfe-lg-2 {
    padding-left: 0.5rem !important;
  }
  .p-lg-3 {
    padding: 1rem !important;
  }
  .pt-lg-3,
  .py-lg-3 {
    padding-top: 1rem !important;
  }
  .pr-lg-3,
  .px-lg-3 {
    padding-right: 1rem !important;
  }
  .pb-lg-3,
  .py-lg-3 {
    padding-bottom: 1rem !important;
  }
  .pl-lg-3,
  .px-lg-3 {
    padding-left: 1rem !important;
  }
  html:not([dir='rtl']) .pfs-lg-3 {
    padding-left: 1rem !important;
  }
  *[dir='rtl'] .pfs-lg-3 {
    padding-right: 1rem !important;
  }
  html:not([dir='rtl']) .pfe-lg-3 {
    padding-right: 1rem !important;
  }
  *[dir='rtl'] .pfe-lg-3 {
    padding-left: 1rem !important;
  }
  .p-lg-4 {
    padding: 1.5rem !important;
  }
  .pt-lg-4,
  .py-lg-4 {
    padding-top: 1.5rem !important;
  }
  .pr-lg-4,
  .px-lg-4 {
    padding-right: 1.5rem !important;
  }
  .pb-lg-4,
  .py-lg-4 {
    padding-bottom: 1.5rem !important;
  }
  .pl-lg-4,
  .px-lg-4 {
    padding-left: 1.5rem !important;
  }
  html:not([dir='rtl']) .pfs-lg-4 {
    padding-left: 1.5rem !important;
  }
  *[dir='rtl'] .pfs-lg-4 {
    padding-right: 1.5rem !important;
  }
  html:not([dir='rtl']) .pfe-lg-4 {
    padding-right: 1.5rem !important;
  }
  *[dir='rtl'] .pfe-lg-4 {
    padding-left: 1.5rem !important;
  }
  .p-lg-5 {
    padding: 3rem !important;
  }
  .pt-lg-5,
  .py-lg-5 {
    padding-top: 3rem !important;
  }
  .pr-lg-5,
  .px-lg-5 {
    padding-right: 3rem !important;
  }
  .pb-lg-5,
  .py-lg-5 {
    padding-bottom: 3rem !important;
  }
  .pl-lg-5,
  .px-lg-5 {
    padding-left: 3rem !important;
  }
  html:not([dir='rtl']) .pfs-lg-5 {
    padding-left: 3rem !important;
  }
  *[dir='rtl'] .pfs-lg-5 {
    padding-right: 3rem !important;
  }
  html:not([dir='rtl']) .pfe-lg-5 {
    padding-right: 3rem !important;
  }
  *[dir='rtl'] .pfe-lg-5 {
    padding-left: 3rem !important;
  }
  .m-lg-n1 {
    margin: -0.25rem !important;
  }
  .mt-lg-n1,
  .my-lg-n1 {
    margin-top: -0.25rem !important;
  }
  .mr-lg-n1,
  .mx-lg-n1 {
    margin-right: -0.25rem !important;
  }
  .mb-lg-n1,
  .my-lg-n1 {
    margin-bottom: -0.25rem !important;
  }
  .ml-lg-n1,
  .mx-lg-n1 {
    margin-left: -0.25rem !important;
  }
  html:not([dir='rtl']) .mfs-lg-n1 {
    margin-left: -0.25rem !important;
  }
  *[dir='rtl'] .mfs-lg-n1 {
    margin-right: -0.25rem !important;
  }
  html:not([dir='rtl']) .mfe-lg-n1 {
    margin-right: -0.25rem !important;
  }
  *[dir='rtl'] .mfe-lg-n1 {
    margin-left: -0.25rem !important;
  }
  .m-lg-n2 {
    margin: -0.5rem !important;
  }
  .mt-lg-n2,
  .my-lg-n2 {
    margin-top: -0.5rem !important;
  }
  .mr-lg-n2,
  .mx-lg-n2 {
    margin-right: -0.5rem !important;
  }
  .mb-lg-n2,
  .my-lg-n2 {
    margin-bottom: -0.5rem !important;
  }
  .ml-lg-n2,
  .mx-lg-n2 {
    margin-left: -0.5rem !important;
  }
  html:not([dir='rtl']) .mfs-lg-n2 {
    margin-left: -0.5rem !important;
  }
  *[dir='rtl'] .mfs-lg-n2 {
    margin-right: -0.5rem !important;
  }
  html:not([dir='rtl']) .mfe-lg-n2 {
    margin-right: -0.5rem !important;
  }
  *[dir='rtl'] .mfe-lg-n2 {
    margin-left: -0.5rem !important;
  }
  .m-lg-n3 {
    margin: -1rem !important;
  }
  .mt-lg-n3,
  .my-lg-n3 {
    margin-top: -1rem !important;
  }
  .mr-lg-n3,
  .mx-lg-n3 {
    margin-right: -1rem !important;
  }
  .mb-lg-n3,
  .my-lg-n3 {
    margin-bottom: -1rem !important;
  }
  .ml-lg-n3,
  .mx-lg-n3 {
    margin-left: -1rem !important;
  }
  html:not([dir='rtl']) .mfs-lg-n3 {
    margin-left: -1rem !important;
  }
  *[dir='rtl'] .mfs-lg-n3 {
    margin-right: -1rem !important;
  }
  html:not([dir='rtl']) .mfe-lg-n3 {
    margin-right: -1rem !important;
  }
  *[dir='rtl'] .mfe-lg-n3 {
    margin-left: -1rem !important;
  }
  .m-lg-n4 {
    margin: -1.5rem !important;
  }
  .mt-lg-n4,
  .my-lg-n4 {
    margin-top: -1.5rem !important;
  }
  .mr-lg-n4,
  .mx-lg-n4 {
    margin-right: -1.5rem !important;
  }
  .mb-lg-n4,
  .my-lg-n4 {
    margin-bottom: -1.5rem !important;
  }
  .ml-lg-n4,
  .mx-lg-n4 {
    margin-left: -1.5rem !important;
  }
  html:not([dir='rtl']) .mfs-lg-n4 {
    margin-left: -1.5rem !important;
  }
  *[dir='rtl'] .mfs-lg-n4 {
    margin-right: -1.5rem !important;
  }
  html:not([dir='rtl']) .mfe-lg-n4 {
    margin-right: -1.5rem !important;
  }
  *[dir='rtl'] .mfe-lg-n4 {
    margin-left: -1.5rem !important;
  }
  .m-lg-n5 {
    margin: -3rem !important;
  }
  .mt-lg-n5,
  .my-lg-n5 {
    margin-top: -3rem !important;
  }
  .mr-lg-n5,
  .mx-lg-n5 {
    margin-right: -3rem !important;
  }
  .mb-lg-n5,
  .my-lg-n5 {
    margin-bottom: -3rem !important;
  }
  .ml-lg-n5,
  .mx-lg-n5 {
    margin-left: -3rem !important;
  }
  html:not([dir='rtl']) .mfs-lg-n5 {
    margin-left: -3rem !important;
  }
  *[dir='rtl'] .mfs-lg-n5 {
    margin-right: -3rem !important;
  }
  html:not([dir='rtl']) .mfe-lg-n5 {
    margin-right: -3rem !important;
  }
  *[dir='rtl'] .mfe-lg-n5 {
    margin-left: -3rem !important;
  }
  .m-lg-auto {
    margin: auto !important;
  }
  .mt-lg-auto,
  .my-lg-auto {
    margin-top: auto !important;
  }
  .mr-lg-auto,
  .mx-lg-auto {
    margin-right: auto !important;
  }
  .mb-lg-auto,
  .my-lg-auto {
    margin-bottom: auto !important;
  }
  .ml-lg-auto,
  .mx-lg-auto {
    margin-left: auto !important;
  }
  html:not([dir='rtl']) .mfs-lg-auto {
    margin-left: auto !important;
  }
  *[dir='rtl'] .mfs-lg-auto {
    margin-right: auto !important;
  }
  html:not([dir='rtl']) .mfe-lg-auto {
    margin-right: auto !important;
  }
  *[dir='rtl'] .mfe-lg-auto {
    margin-left: auto !important;
  }
}

@media (min-width: 1200px) {
  .m-xl-0 {
    margin: 0 !important;
  }
  .mt-xl-0,
  .my-xl-0 {
    margin-top: 0 !important;
  }
  .mr-xl-0,
  .mx-xl-0 {
    margin-right: 0 !important;
  }
  .mb-xl-0,
  .my-xl-0 {
    margin-bottom: 0 !important;
  }
  .ml-xl-0,
  .mx-xl-0 {
    margin-left: 0 !important;
  }
  html:not([dir='rtl']) .mfs-xl-0 {
    margin-left: 0 !important;
  }
  *[dir='rtl'] .mfs-xl-0 {
    margin-right: 0 !important;
  }
  html:not([dir='rtl']) .mfe-xl-0 {
    margin-right: 0 !important;
  }
  *[dir='rtl'] .mfe-xl-0 {
    margin-left: 0 !important;
  }
  .m-xl-1 {
    margin: 0.25rem !important;
  }
  .mt-xl-1,
  .my-xl-1 {
    margin-top: 0.25rem !important;
  }
  .mr-xl-1,
  .mx-xl-1 {
    margin-right: 0.25rem !important;
  }
  .mb-xl-1,
  .my-xl-1 {
    margin-bottom: 0.25rem !important;
  }
  .ml-xl-1,
  .mx-xl-1 {
    margin-left: 0.25rem !important;
  }
  html:not([dir='rtl']) .mfs-xl-1 {
    margin-left: 0.25rem !important;
  }
  *[dir='rtl'] .mfs-xl-1 {
    margin-right: 0.25rem !important;
  }
  html:not([dir='rtl']) .mfe-xl-1 {
    margin-right: 0.25rem !important;
  }
  *[dir='rtl'] .mfe-xl-1 {
    margin-left: 0.25rem !important;
  }
  .m-xl-2 {
    margin: 0.5rem !important;
  }
  .mt-xl-2,
  .my-xl-2 {
    margin-top: 0.5rem !important;
  }
  .mr-xl-2,
  .mx-xl-2 {
    margin-right: 0.5rem !important;
  }
  .mb-xl-2,
  .my-xl-2 {
    margin-bottom: 0.5rem !important;
  }
  .ml-xl-2,
  .mx-xl-2 {
    margin-left: 0.5rem !important;
  }
  html:not([dir='rtl']) .mfs-xl-2 {
    margin-left: 0.5rem !important;
  }
  *[dir='rtl'] .mfs-xl-2 {
    margin-right: 0.5rem !important;
  }
  html:not([dir='rtl']) .mfe-xl-2 {
    margin-right: 0.5rem !important;
  }
  *[dir='rtl'] .mfe-xl-2 {
    margin-left: 0.5rem !important;
  }
  .m-xl-3 {
    margin: 1rem !important;
  }
  .mt-xl-3,
  .my-xl-3 {
    margin-top: 1rem !important;
  }
  .mr-xl-3,
  .mx-xl-3 {
    margin-right: 1rem !important;
  }
  .mb-xl-3,
  .my-xl-3 {
    margin-bottom: 1rem !important;
  }
  .ml-xl-3,
  .mx-xl-3 {
    margin-left: 1rem !important;
  }
  html:not([dir='rtl']) .mfs-xl-3 {
    margin-left: 1rem !important;
  }
  *[dir='rtl'] .mfs-xl-3 {
    margin-right: 1rem !important;
  }
  html:not([dir='rtl']) .mfe-xl-3 {
    margin-right: 1rem !important;
  }
  *[dir='rtl'] .mfe-xl-3 {
    margin-left: 1rem !important;
  }
  .m-xl-4 {
    margin: 1.5rem !important;
  }
  .mt-xl-4,
  .my-xl-4 {
    margin-top: 1.5rem !important;
  }
  .mr-xl-4,
  .mx-xl-4 {
    margin-right: 1.5rem !important;
  }
  .mb-xl-4,
  .my-xl-4 {
    margin-bottom: 1.5rem !important;
  }
  .ml-xl-4,
  .mx-xl-4 {
    margin-left: 1.5rem !important;
  }
  html:not([dir='rtl']) .mfs-xl-4 {
    margin-left: 1.5rem !important;
  }
  *[dir='rtl'] .mfs-xl-4 {
    margin-right: 1.5rem !important;
  }
  html:not([dir='rtl']) .mfe-xl-4 {
    margin-right: 1.5rem !important;
  }
  *[dir='rtl'] .mfe-xl-4 {
    margin-left: 1.5rem !important;
  }
  .m-xl-5 {
    margin: 3rem !important;
  }
  .mt-xl-5,
  .my-xl-5 {
    margin-top: 3rem !important;
  }
  .mr-xl-5,
  .mx-xl-5 {
    margin-right: 3rem !important;
  }
  .mb-xl-5,
  .my-xl-5 {
    margin-bottom: 3rem !important;
  }
  .ml-xl-5,
  .mx-xl-5 {
    margin-left: 3rem !important;
  }
  html:not([dir='rtl']) .mfs-xl-5 {
    margin-left: 3rem !important;
  }
  *[dir='rtl'] .mfs-xl-5 {
    margin-right: 3rem !important;
  }
  html:not([dir='rtl']) .mfe-xl-5 {
    margin-right: 3rem !important;
  }
  *[dir='rtl'] .mfe-xl-5 {
    margin-left: 3rem !important;
  }
  .p-xl-0 {
    padding: 0 !important;
  }
  .pt-xl-0,
  .py-xl-0 {
    padding-top: 0 !important;
  }
  .pr-xl-0,
  .px-xl-0 {
    padding-right: 0 !important;
  }
  .pb-xl-0,
  .py-xl-0 {
    padding-bottom: 0 !important;
  }
  .pl-xl-0,
  .px-xl-0 {
    padding-left: 0 !important;
  }
  html:not([dir='rtl']) .pfs-xl-0 {
    padding-left: 0 !important;
  }
  *[dir='rtl'] .pfs-xl-0 {
    padding-right: 0 !important;
  }
  html:not([dir='rtl']) .pfe-xl-0 {
    padding-right: 0 !important;
  }
  *[dir='rtl'] .pfe-xl-0 {
    padding-left: 0 !important;
  }
  .p-xl-1 {
    padding: 0.25rem !important;
  }
  .pt-xl-1,
  .py-xl-1 {
    padding-top: 0.25rem !important;
  }
  .pr-xl-1,
  .px-xl-1 {
    padding-right: 0.25rem !important;
  }
  .pb-xl-1,
  .py-xl-1 {
    padding-bottom: 0.25rem !important;
  }
  .pl-xl-1,
  .px-xl-1 {
    padding-left: 0.25rem !important;
  }
  html:not([dir='rtl']) .pfs-xl-1 {
    padding-left: 0.25rem !important;
  }
  *[dir='rtl'] .pfs-xl-1 {
    padding-right: 0.25rem !important;
  }
  html:not([dir='rtl']) .pfe-xl-1 {
    padding-right: 0.25rem !important;
  }
  *[dir='rtl'] .pfe-xl-1 {
    padding-left: 0.25rem !important;
  }
  .p-xl-2 {
    padding: 0.5rem !important;
  }
  .pt-xl-2,
  .py-xl-2 {
    padding-top: 0.5rem !important;
  }
  .pr-xl-2,
  .px-xl-2 {
    padding-right: 0.5rem !important;
  }
  .pb-xl-2,
  .py-xl-2 {
    padding-bottom: 0.5rem !important;
  }
  .pl-xl-2,
  .px-xl-2 {
    padding-left: 0.5rem !important;
  }
  html:not([dir='rtl']) .pfs-xl-2 {
    padding-left: 0.5rem !important;
  }
  *[dir='rtl'] .pfs-xl-2 {
    padding-right: 0.5rem !important;
  }
  html:not([dir='rtl']) .pfe-xl-2 {
    padding-right: 0.5rem !important;
  }
  *[dir='rtl'] .pfe-xl-2 {
    padding-left: 0.5rem !important;
  }
  .p-xl-3 {
    padding: 1rem !important;
  }
  .pt-xl-3,
  .py-xl-3 {
    padding-top: 1rem !important;
  }
  .pr-xl-3,
  .px-xl-3 {
    padding-right: 1rem !important;
  }
  .pb-xl-3,
  .py-xl-3 {
    padding-bottom: 1rem !important;
  }
  .pl-xl-3,
  .px-xl-3 {
    padding-left: 1rem !important;
  }
  html:not([dir='rtl']) .pfs-xl-3 {
    padding-left: 1rem !important;
  }
  *[dir='rtl'] .pfs-xl-3 {
    padding-right: 1rem !important;
  }
  html:not([dir='rtl']) .pfe-xl-3 {
    padding-right: 1rem !important;
  }
  *[dir='rtl'] .pfe-xl-3 {
    padding-left: 1rem !important;
  }
  .p-xl-4 {
    padding: 1.5rem !important;
  }
  .pt-xl-4,
  .py-xl-4 {
    padding-top: 1.5rem !important;
  }
  .pr-xl-4,
  .px-xl-4 {
    padding-right: 1.5rem !important;
  }
  .pb-xl-4,
  .py-xl-4 {
    padding-bottom: 1.5rem !important;
  }
  .pl-xl-4,
  .px-xl-4 {
    padding-left: 1.5rem !important;
  }
  html:not([dir='rtl']) .pfs-xl-4 {
    padding-left: 1.5rem !important;
  }
  *[dir='rtl'] .pfs-xl-4 {
    padding-right: 1.5rem !important;
  }
  html:not([dir='rtl']) .pfe-xl-4 {
    padding-right: 1.5rem !important;
  }
  *[dir='rtl'] .pfe-xl-4 {
    padding-left: 1.5rem !important;
  }
  .p-xl-5 {
    padding: 3rem !important;
  }
  .pt-xl-5,
  .py-xl-5 {
    padding-top: 3rem !important;
  }
  .pr-xl-5,
  .px-xl-5 {
    padding-right: 3rem !important;
  }
  .pb-xl-5,
  .py-xl-5 {
    padding-bottom: 3rem !important;
  }
  .pl-xl-5,
  .px-xl-5 {
    padding-left: 3rem !important;
  }
  html:not([dir='rtl']) .pfs-xl-5 {
    padding-left: 3rem !important;
  }
  *[dir='rtl'] .pfs-xl-5 {
    padding-right: 3rem !important;
  }
  html:not([dir='rtl']) .pfe-xl-5 {
    padding-right: 3rem !important;
  }
  *[dir='rtl'] .pfe-xl-5 {
    padding-left: 3rem !important;
  }
  .m-xl-n1 {
    margin: -0.25rem !important;
  }
  .mt-xl-n1,
  .my-xl-n1 {
    margin-top: -0.25rem !important;
  }
  .mr-xl-n1,
  .mx-xl-n1 {
    margin-right: -0.25rem !important;
  }
  .mb-xl-n1,
  .my-xl-n1 {
    margin-bottom: -0.25rem !important;
  }
  .ml-xl-n1,
  .mx-xl-n1 {
    margin-left: -0.25rem !important;
  }
  html:not([dir='rtl']) .mfs-xl-n1 {
    margin-left: -0.25rem !important;
  }
  *[dir='rtl'] .mfs-xl-n1 {
    margin-right: -0.25rem !important;
  }
  html:not([dir='rtl']) .mfe-xl-n1 {
    margin-right: -0.25rem !important;
  }
  *[dir='rtl'] .mfe-xl-n1 {
    margin-left: -0.25rem !important;
  }
  .m-xl-n2 {
    margin: -0.5rem !important;
  }
  .mt-xl-n2,
  .my-xl-n2 {
    margin-top: -0.5rem !important;
  }
  .mr-xl-n2,
  .mx-xl-n2 {
    margin-right: -0.5rem !important;
  }
  .mb-xl-n2,
  .my-xl-n2 {
    margin-bottom: -0.5rem !important;
  }
  .ml-xl-n2,
  .mx-xl-n2 {
    margin-left: -0.5rem !important;
  }
  html:not([dir='rtl']) .mfs-xl-n2 {
    margin-left: -0.5rem !important;
  }
  *[dir='rtl'] .mfs-xl-n2 {
    margin-right: -0.5rem !important;
  }
  html:not([dir='rtl']) .mfe-xl-n2 {
    margin-right: -0.5rem !important;
  }
  *[dir='rtl'] .mfe-xl-n2 {
    margin-left: -0.5rem !important;
  }
  .m-xl-n3 {
    margin: -1rem !important;
  }
  .mt-xl-n3,
  .my-xl-n3 {
    margin-top: -1rem !important;
  }
  .mr-xl-n3,
  .mx-xl-n3 {
    margin-right: -1rem !important;
  }
  .mb-xl-n3,
  .my-xl-n3 {
    margin-bottom: -1rem !important;
  }
  .ml-xl-n3,
  .mx-xl-n3 {
    margin-left: -1rem !important;
  }
  html:not([dir='rtl']) .mfs-xl-n3 {
    margin-left: -1rem !important;
  }
  *[dir='rtl'] .mfs-xl-n3 {
    margin-right: -1rem !important;
  }
  html:not([dir='rtl']) .mfe-xl-n3 {
    margin-right: -1rem !important;
  }
  *[dir='rtl'] .mfe-xl-n3 {
    margin-left: -1rem !important;
  }
  .m-xl-n4 {
    margin: -1.5rem !important;
  }
  .mt-xl-n4,
  .my-xl-n4 {
    margin-top: -1.5rem !important;
  }
  .mr-xl-n4,
  .mx-xl-n4 {
    margin-right: -1.5rem !important;
  }
  .mb-xl-n4,
  .my-xl-n4 {
    margin-bottom: -1.5rem !important;
  }
  .ml-xl-n4,
  .mx-xl-n4 {
    margin-left: -1.5rem !important;
  }
  html:not([dir='rtl']) .mfs-xl-n4 {
    margin-left: -1.5rem !important;
  }
  *[dir='rtl'] .mfs-xl-n4 {
    margin-right: -1.5rem !important;
  }
  html:not([dir='rtl']) .mfe-xl-n4 {
    margin-right: -1.5rem !important;
  }
  *[dir='rtl'] .mfe-xl-n4 {
    margin-left: -1.5rem !important;
  }
  .m-xl-n5 {
    margin: -3rem !important;
  }
  .mt-xl-n5,
  .my-xl-n5 {
    margin-top: -3rem !important;
  }
  .mr-xl-n5,
  .mx-xl-n5 {
    margin-right: -3rem !important;
  }
  .mb-xl-n5,
  .my-xl-n5 {
    margin-bottom: -3rem !important;
  }
  .ml-xl-n5,
  .mx-xl-n5 {
    margin-left: -3rem !important;
  }
  html:not([dir='rtl']) .mfs-xl-n5 {
    margin-left: -3rem !important;
  }
  *[dir='rtl'] .mfs-xl-n5 {
    margin-right: -3rem !important;
  }
  html:not([dir='rtl']) .mfe-xl-n5 {
    margin-right: -3rem !important;
  }
  *[dir='rtl'] .mfe-xl-n5 {
    margin-left: -3rem !important;
  }
  .m-xl-auto {
    margin: auto !important;
  }
  .mt-xl-auto,
  .my-xl-auto {
    margin-top: auto !important;
  }
  .mr-xl-auto,
  .mx-xl-auto {
    margin-right: auto !important;
  }
  .mb-xl-auto,
  .my-xl-auto {
    margin-bottom: auto !important;
  }
  .ml-xl-auto,
  .mx-xl-auto {
    margin-left: auto !important;
  }
  html:not([dir='rtl']) .mfs-xl-auto {
    margin-left: auto !important;
  }
  *[dir='rtl'] .mfs-xl-auto {
    margin-right: auto !important;
  }
  html:not([dir='rtl']) .mfe-xl-auto {
    margin-right: auto !important;
  }
  *[dir='rtl'] .mfe-xl-auto {
    margin-left: auto !important;
  }
}

@media (min-width: 1400px) {
  .m-xxl-0 {
    margin: 0 !important;
  }
  .mt-xxl-0,
  .my-xxl-0 {
    margin-top: 0 !important;
  }
  .mr-xxl-0,
  .mx-xxl-0 {
    margin-right: 0 !important;
  }
  .mb-xxl-0,
  .my-xxl-0 {
    margin-bottom: 0 !important;
  }
  .ml-xxl-0,
  .mx-xxl-0 {
    margin-left: 0 !important;
  }
  html:not([dir='rtl']) .mfs-xxl-0 {
    margin-left: 0 !important;
  }
  *[dir='rtl'] .mfs-xxl-0 {
    margin-right: 0 !important;
  }
  html:not([dir='rtl']) .mfe-xxl-0 {
    margin-right: 0 !important;
  }
  *[dir='rtl'] .mfe-xxl-0 {
    margin-left: 0 !important;
  }
  .m-xxl-1 {
    margin: 0.25rem !important;
  }
  .mt-xxl-1,
  .my-xxl-1 {
    margin-top: 0.25rem !important;
  }
  .mr-xxl-1,
  .mx-xxl-1 {
    margin-right: 0.25rem !important;
  }
  .mb-xxl-1,
  .my-xxl-1 {
    margin-bottom: 0.25rem !important;
  }
  .ml-xxl-1,
  .mx-xxl-1 {
    margin-left: 0.25rem !important;
  }
  html:not([dir='rtl']) .mfs-xxl-1 {
    margin-left: 0.25rem !important;
  }
  *[dir='rtl'] .mfs-xxl-1 {
    margin-right: 0.25rem !important;
  }
  html:not([dir='rtl']) .mfe-xxl-1 {
    margin-right: 0.25rem !important;
  }
  *[dir='rtl'] .mfe-xxl-1 {
    margin-left: 0.25rem !important;
  }
  .m-xxl-2 {
    margin: 0.5rem !important;
  }
  .mt-xxl-2,
  .my-xxl-2 {
    margin-top: 0.5rem !important;
  }
  .mr-xxl-2,
  .mx-xxl-2 {
    margin-right: 0.5rem !important;
  }
  .mb-xxl-2,
  .my-xxl-2 {
    margin-bottom: 0.5rem !important;
  }
  .ml-xxl-2,
  .mx-xxl-2 {
    margin-left: 0.5rem !important;
  }
  html:not([dir='rtl']) .mfs-xxl-2 {
    margin-left: 0.5rem !important;
  }
  *[dir='rtl'] .mfs-xxl-2 {
    margin-right: 0.5rem !important;
  }
  html:not([dir='rtl']) .mfe-xxl-2 {
    margin-right: 0.5rem !important;
  }
  *[dir='rtl'] .mfe-xxl-2 {
    margin-left: 0.5rem !important;
  }
  .m-xxl-3 {
    margin: 1rem !important;
  }
  .mt-xxl-3,
  .my-xxl-3 {
    margin-top: 1rem !important;
  }
  .mr-xxl-3,
  .mx-xxl-3 {
    margin-right: 1rem !important;
  }
  .mb-xxl-3,
  .my-xxl-3 {
    margin-bottom: 1rem !important;
  }
  .ml-xxl-3,
  .mx-xxl-3 {
    margin-left: 1rem !important;
  }
  html:not([dir='rtl']) .mfs-xxl-3 {
    margin-left: 1rem !important;
  }
  *[dir='rtl'] .mfs-xxl-3 {
    margin-right: 1rem !important;
  }
  html:not([dir='rtl']) .mfe-xxl-3 {
    margin-right: 1rem !important;
  }
  *[dir='rtl'] .mfe-xxl-3 {
    margin-left: 1rem !important;
  }
  .m-xxl-4 {
    margin: 1.5rem !important;
  }
  .mt-xxl-4,
  .my-xxl-4 {
    margin-top: 1.5rem !important;
  }
  .mr-xxl-4,
  .mx-xxl-4 {
    margin-right: 1.5rem !important;
  }
  .mb-xxl-4,
  .my-xxl-4 {
    margin-bottom: 1.5rem !important;
  }
  .ml-xxl-4,
  .mx-xxl-4 {
    margin-left: 1.5rem !important;
  }
  html:not([dir='rtl']) .mfs-xxl-4 {
    margin-left: 1.5rem !important;
  }
  *[dir='rtl'] .mfs-xxl-4 {
    margin-right: 1.5rem !important;
  }
  html:not([dir='rtl']) .mfe-xxl-4 {
    margin-right: 1.5rem !important;
  }
  *[dir='rtl'] .mfe-xxl-4 {
    margin-left: 1.5rem !important;
  }
  .m-xxl-5 {
    margin: 3rem !important;
  }
  .mt-xxl-5,
  .my-xxl-5 {
    margin-top: 3rem !important;
  }
  .mr-xxl-5,
  .mx-xxl-5 {
    margin-right: 3rem !important;
  }
  .mb-xxl-5,
  .my-xxl-5 {
    margin-bottom: 3rem !important;
  }
  .ml-xxl-5,
  .mx-xxl-5 {
    margin-left: 3rem !important;
  }
  html:not([dir='rtl']) .mfs-xxl-5 {
    margin-left: 3rem !important;
  }
  *[dir='rtl'] .mfs-xxl-5 {
    margin-right: 3rem !important;
  }
  html:not([dir='rtl']) .mfe-xxl-5 {
    margin-right: 3rem !important;
  }
  *[dir='rtl'] .mfe-xxl-5 {
    margin-left: 3rem !important;
  }
  .p-xxl-0 {
    padding: 0 !important;
  }
  .pt-xxl-0,
  .py-xxl-0 {
    padding-top: 0 !important;
  }
  .pr-xxl-0,
  .px-xxl-0 {
    padding-right: 0 !important;
  }
  .pb-xxl-0,
  .py-xxl-0 {
    padding-bottom: 0 !important;
  }
  .pl-xxl-0,
  .px-xxl-0 {
    padding-left: 0 !important;
  }
  html:not([dir='rtl']) .pfs-xxl-0 {
    padding-left: 0 !important;
  }
  *[dir='rtl'] .pfs-xxl-0 {
    padding-right: 0 !important;
  }
  html:not([dir='rtl']) .pfe-xxl-0 {
    padding-right: 0 !important;
  }
  *[dir='rtl'] .pfe-xxl-0 {
    padding-left: 0 !important;
  }
  .p-xxl-1 {
    padding: 0.25rem !important;
  }
  .pt-xxl-1,
  .py-xxl-1 {
    padding-top: 0.25rem !important;
  }
  .pr-xxl-1,
  .px-xxl-1 {
    padding-right: 0.25rem !important;
  }
  .pb-xxl-1,
  .py-xxl-1 {
    padding-bottom: 0.25rem !important;
  }
  .pl-xxl-1,
  .px-xxl-1 {
    padding-left: 0.25rem !important;
  }
  html:not([dir='rtl']) .pfs-xxl-1 {
    padding-left: 0.25rem !important;
  }
  *[dir='rtl'] .pfs-xxl-1 {
    padding-right: 0.25rem !important;
  }
  html:not([dir='rtl']) .pfe-xxl-1 {
    padding-right: 0.25rem !important;
  }
  *[dir='rtl'] .pfe-xxl-1 {
    padding-left: 0.25rem !important;
  }
  .p-xxl-2 {
    padding: 0.5rem !important;
  }
  .pt-xxl-2,
  .py-xxl-2 {
    padding-top: 0.5rem !important;
  }
  .pr-xxl-2,
  .px-xxl-2 {
    padding-right: 0.5rem !important;
  }
  .pb-xxl-2,
  .py-xxl-2 {
    padding-bottom: 0.5rem !important;
  }
  .pl-xxl-2,
  .px-xxl-2 {
    padding-left: 0.5rem !important;
  }
  html:not([dir='rtl']) .pfs-xxl-2 {
    padding-left: 0.5rem !important;
  }
  *[dir='rtl'] .pfs-xxl-2 {
    padding-right: 0.5rem !important;
  }
  html:not([dir='rtl']) .pfe-xxl-2 {
    padding-right: 0.5rem !important;
  }
  *[dir='rtl'] .pfe-xxl-2 {
    padding-left: 0.5rem !important;
  }
  .p-xxl-3 {
    padding: 1rem !important;
  }
  .pt-xxl-3,
  .py-xxl-3 {
    padding-top: 1rem !important;
  }
  .pr-xxl-3,
  .px-xxl-3 {
    padding-right: 1rem !important;
  }
  .pb-xxl-3,
  .py-xxl-3 {
    padding-bottom: 1rem !important;
  }
  .pl-xxl-3,
  .px-xxl-3 {
    padding-left: 1rem !important;
  }
  html:not([dir='rtl']) .pfs-xxl-3 {
    padding-left: 1rem !important;
  }
  *[dir='rtl'] .pfs-xxl-3 {
    padding-right: 1rem !important;
  }
  html:not([dir='rtl']) .pfe-xxl-3 {
    padding-right: 1rem !important;
  }
  *[dir='rtl'] .pfe-xxl-3 {
    padding-left: 1rem !important;
  }
  .p-xxl-4 {
    padding: 1.5rem !important;
  }
  .pt-xxl-4,
  .py-xxl-4 {
    padding-top: 1.5rem !important;
  }
  .pr-xxl-4,
  .px-xxl-4 {
    padding-right: 1.5rem !important;
  }
  .pb-xxl-4,
  .py-xxl-4 {
    padding-bottom: 1.5rem !important;
  }
  .pl-xxl-4,
  .px-xxl-4 {
    padding-left: 1.5rem !important;
  }
  html:not([dir='rtl']) .pfs-xxl-4 {
    padding-left: 1.5rem !important;
  }
  *[dir='rtl'] .pfs-xxl-4 {
    padding-right: 1.5rem !important;
  }
  html:not([dir='rtl']) .pfe-xxl-4 {
    padding-right: 1.5rem !important;
  }
  *[dir='rtl'] .pfe-xxl-4 {
    padding-left: 1.5rem !important;
  }
  .p-xxl-5 {
    padding: 3rem !important;
  }
  .pt-xxl-5,
  .py-xxl-5 {
    padding-top: 3rem !important;
  }
  .pr-xxl-5,
  .px-xxl-5 {
    padding-right: 3rem !important;
  }
  .pb-xxl-5,
  .py-xxl-5 {
    padding-bottom: 3rem !important;
  }
  .pl-xxl-5,
  .px-xxl-5 {
    padding-left: 3rem !important;
  }
  html:not([dir='rtl']) .pfs-xxl-5 {
    padding-left: 3rem !important;
  }
  *[dir='rtl'] .pfs-xxl-5 {
    padding-right: 3rem !important;
  }
  html:not([dir='rtl']) .pfe-xxl-5 {
    padding-right: 3rem !important;
  }
  *[dir='rtl'] .pfe-xxl-5 {
    padding-left: 3rem !important;
  }
  .m-xxl-n1 {
    margin: -0.25rem !important;
  }
  .mt-xxl-n1,
  .my-xxl-n1 {
    margin-top: -0.25rem !important;
  }
  .mr-xxl-n1,
  .mx-xxl-n1 {
    margin-right: -0.25rem !important;
  }
  .mb-xxl-n1,
  .my-xxl-n1 {
    margin-bottom: -0.25rem !important;
  }
  .ml-xxl-n1,
  .mx-xxl-n1 {
    margin-left: -0.25rem !important;
  }
  html:not([dir='rtl']) .mfs-xxl-n1 {
    margin-left: -0.25rem !important;
  }
  *[dir='rtl'] .mfs-xxl-n1 {
    margin-right: -0.25rem !important;
  }
  html:not([dir='rtl']) .mfe-xxl-n1 {
    margin-right: -0.25rem !important;
  }
  *[dir='rtl'] .mfe-xxl-n1 {
    margin-left: -0.25rem !important;
  }
  .m-xxl-n2 {
    margin: -0.5rem !important;
  }
  .mt-xxl-n2,
  .my-xxl-n2 {
    margin-top: -0.5rem !important;
  }
  .mr-xxl-n2,
  .mx-xxl-n2 {
    margin-right: -0.5rem !important;
  }
  .mb-xxl-n2,
  .my-xxl-n2 {
    margin-bottom: -0.5rem !important;
  }
  .ml-xxl-n2,
  .mx-xxl-n2 {
    margin-left: -0.5rem !important;
  }
  html:not([dir='rtl']) .mfs-xxl-n2 {
    margin-left: -0.5rem !important;
  }
  *[dir='rtl'] .mfs-xxl-n2 {
    margin-right: -0.5rem !important;
  }
  html:not([dir='rtl']) .mfe-xxl-n2 {
    margin-right: -0.5rem !important;
  }
  *[dir='rtl'] .mfe-xxl-n2 {
    margin-left: -0.5rem !important;
  }
  .m-xxl-n3 {
    margin: -1rem !important;
  }
  .mt-xxl-n3,
  .my-xxl-n3 {
    margin-top: -1rem !important;
  }
  .mr-xxl-n3,
  .mx-xxl-n3 {
    margin-right: -1rem !important;
  }
  .mb-xxl-n3,
  .my-xxl-n3 {
    margin-bottom: -1rem !important;
  }
  .ml-xxl-n3,
  .mx-xxl-n3 {
    margin-left: -1rem !important;
  }
  html:not([dir='rtl']) .mfs-xxl-n3 {
    margin-left: -1rem !important;
  }
  *[dir='rtl'] .mfs-xxl-n3 {
    margin-right: -1rem !important;
  }
  html:not([dir='rtl']) .mfe-xxl-n3 {
    margin-right: -1rem !important;
  }
  *[dir='rtl'] .mfe-xxl-n3 {
    margin-left: -1rem !important;
  }
  .m-xxl-n4 {
    margin: -1.5rem !important;
  }
  .mt-xxl-n4,
  .my-xxl-n4 {
    margin-top: -1.5rem !important;
  }
  .mr-xxl-n4,
  .mx-xxl-n4 {
    margin-right: -1.5rem !important;
  }
  .mb-xxl-n4,
  .my-xxl-n4 {
    margin-bottom: -1.5rem !important;
  }
  .ml-xxl-n4,
  .mx-xxl-n4 {
    margin-left: -1.5rem !important;
  }
  html:not([dir='rtl']) .mfs-xxl-n4 {
    margin-left: -1.5rem !important;
  }
  *[dir='rtl'] .mfs-xxl-n4 {
    margin-right: -1.5rem !important;
  }
  html:not([dir='rtl']) .mfe-xxl-n4 {
    margin-right: -1.5rem !important;
  }
  *[dir='rtl'] .mfe-xxl-n4 {
    margin-left: -1.5rem !important;
  }
  .m-xxl-n5 {
    margin: -3rem !important;
  }
  .mt-xxl-n5,
  .my-xxl-n5 {
    margin-top: -3rem !important;
  }
  .mr-xxl-n5,
  .mx-xxl-n5 {
    margin-right: -3rem !important;
  }
  .mb-xxl-n5,
  .my-xxl-n5 {
    margin-bottom: -3rem !important;
  }
  .ml-xxl-n5,
  .mx-xxl-n5 {
    margin-left: -3rem !important;
  }
  html:not([dir='rtl']) .mfs-xxl-n5 {
    margin-left: -3rem !important;
  }
  *[dir='rtl'] .mfs-xxl-n5 {
    margin-right: -3rem !important;
  }
  html:not([dir='rtl']) .mfe-xxl-n5 {
    margin-right: -3rem !important;
  }
  *[dir='rtl'] .mfe-xxl-n5 {
    margin-left: -3rem !important;
  }
  .m-xxl-auto {
    margin: auto !important;
  }
  .mt-xxl-auto,
  .my-xxl-auto {
    margin-top: auto !important;
  }
  .mr-xxl-auto,
  .mx-xxl-auto {
    margin-right: auto !important;
  }
  .mb-xxl-auto,
  .my-xxl-auto {
    margin-bottom: auto !important;
  }
  .ml-xxl-auto,
  .mx-xxl-auto {
    margin-left: auto !important;
  }
  html:not([dir='rtl']) .mfs-xxl-auto {
    margin-left: auto !important;
  }
  *[dir='rtl'] .mfs-xxl-auto {
    margin-right: auto !important;
  }
  html:not([dir='rtl']) .mfe-xxl-auto {
    margin-right: auto !important;
  }
  *[dir='rtl'] .mfe-xxl-auto {
    margin-left: auto !important;
  }
}

.stretched-link::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  pointer-events: auto;
  content: '';
  background-color: rgba(0, 0, 21, 0);
}

.text-monospace {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, 'Liberation Mono', 'Courier New', monospace !important;
}

.text-justify {
  text-align: justify !important;
}

.text-wrap {
  white-space: normal !important;
}

.text-nowrap {
  white-space: nowrap !important;
}

.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.text-left {
  text-align: left !important;
}

.text-right {
  text-align: right !important;
}

.text-center {
  text-align: center !important;
}

@media (min-width: 576px) {
  .text-sm-left {
    text-align: left !important;
  }
  .text-sm-right {
    text-align: right !important;
  }
  .text-sm-center {
    text-align: center !important;
  }
}

@media (min-width: 768px) {
  .text-md-left {
    text-align: left !important;
  }
  .text-md-right {
    text-align: right !important;
  }
  .text-md-center {
    text-align: center !important;
  }
}

@media (min-width: 992px) {
  .text-lg-left {
    text-align: left !important;
  }
  .text-lg-right {
    text-align: right !important;
  }
  .text-lg-center {
    text-align: center !important;
  }
}

@media (min-width: 1200px) {
  .text-xl-left {
    text-align: left !important;
  }
  .text-xl-right {
    text-align: right !important;
  }
  .text-xl-center {
    text-align: center !important;
  }
}

@media (min-width: 1400px) {
  .text-xxl-left {
    text-align: left !important;
  }
  .text-xxl-right {
    text-align: right !important;
  }
  .text-xxl-center {
    text-align: center !important;
  }
}

.text-lowercase {
  text-transform: lowercase !important;
}

.text-uppercase {
  text-transform: uppercase !important;
}

.text-capitalize {
  text-transform: capitalize !important;
}

.font-weight-light {
  font-weight: 300 !important;
}

.font-weight-lighter {
  font-weight: lighter !important;
}

.font-weight-normal {
  font-weight: 400 !important;
}

.font-weight-bold {
  font-weight: 700 !important;
}

.font-weight-bolder {
  font-weight: bolder !important;
}

.font-italic {
  font-style: italic !important;
}

.text-white {
  color: #fff !important;
}

.c-dark-theme .text-primary {
  color: #4638c2 !important;
}

.c-dark-theme a.text-primary:hover,
.c-dark-theme a.text-primary:focus {
  color: #312787 !important;
}

.c-dark-theme .text-secondary {
  color: #4c4f54 !important;
}

.c-dark-theme a.text-secondary:hover,
.c-dark-theme a.text-secondary:focus {
  color: #28292c !important;
}

.c-dark-theme .text-success {
  color: #45a164 !important;
}

.c-dark-theme a.text-success:hover,
.c-dark-theme a.text-success:focus {
  color: #2e6b43 !important;
}

.c-dark-theme .text-info {
  color: #4799eb !important;
}

.c-dark-theme a.text-info:hover,
.c-dark-theme a.text-info:focus {
  color: #1773cf !important;
}

.c-dark-theme .text-warning {
  color: #e1a82d !important;
}

.c-dark-theme a.text-warning:hover,
.c-dark-theme a.text-warning:focus {
  color: #a97b18 !important;
}

.c-dark-theme .text-danger {
  color: #d16767 !important;
}

.c-dark-theme a.text-danger:hover,
.c-dark-theme a.text-danger:focus {
  color: #b53636 !important;
}

.c-dark-theme .text-light {
  color: #6c6e7e !important;
}

.c-dark-theme a.text-light:hover,
.c-dark-theme a.text-light:focus {
  color: #494a55 !important;
}

.c-dark-theme .text-dark {
  color: #0e0e15 !important;
}

.c-dark-theme a.text-dark:hover,
.c-dark-theme a.text-dark:focus {
  color: black !important;
}

.c-legacy-theme .text-primary {
  color: #20a8d8 !important;
}

.c-legacy-theme a.text-primary:hover,
.c-legacy-theme a.text-primary:focus {
  color: #167495 !important;
}

.c-legacy-theme .text-secondary {
  color: #c8ced3 !important;
}

.c-legacy-theme a.text-secondary:hover,
.c-legacy-theme a.text-secondary:focus {
  color: #9ea8b1 !important;
}

.c-legacy-theme .text-success {
  color: #4dbd74 !important;
}

.c-legacy-theme a.text-success:hover,
.c-legacy-theme a.text-success:focus {
  color: #338a52 !important;
}

.c-legacy-theme .text-info {
  color: #63c2de !important;
}

.c-legacy-theme a.text-info:hover,
.c-legacy-theme a.text-info:focus {
  color: #2ba6ca !important;
}

.c-legacy-theme .text-warning {
  color: #ffc107 !important;
}

.c-legacy-theme a.text-warning:hover,
.c-legacy-theme a.text-warning:focus {
  color: #ba8b00 !important;
}

.c-legacy-theme .text-danger {
  color: #f86c6b !important;
}

.c-legacy-theme a.text-danger:hover,
.c-legacy-theme a.text-danger:focus {
  color: #f52322 !important;
}

.c-legacy-theme .text-light {
  color: #f0f3f5 !important;
}

.c-legacy-theme a.text-light:hover,
.c-legacy-theme a.text-light:focus {
  color: #c2ced6 !important;
}

.c-legacy-theme .text-dark {
  color: #2f353a !important;
}

.c-legacy-theme a.text-dark:hover,
.c-legacy-theme a.text-dark:focus {
  color: #0d0e10 !important;
}

.text-primary {
  color: #321fdb !important;
}

a.text-primary:hover,
a.text-primary:focus {
  color: #231698 !important;
}

.text-secondary {
  color: #ced2d8 !important;
}

a.text-secondary:hover,
a.text-secondary:focus {
  color: #a3abb6 !important;
}

.text-success {
  color: #2eb85c !important;
}

a.text-success:hover,
a.text-success:focus {
  color: #1f7b3d !important;
}

.text-info {
  color: #39f !important;
}

a.text-info:hover,
a.text-info:focus {
  color: #0073e6 !important;
}

.text-warning {
  color: #f9b115 !important;
}

a.text-warning:hover,
a.text-warning:focus {
  color: #bd8305 !important;
}

.text-danger {
  color: #e55353 !important;
}

a.text-danger:hover,
a.text-danger:focus {
  color: #cd1f1f !important;
}

.text-light {
  color: #ebedef !important;
}

a.text-light:hover,
a.text-light:focus {
  color: #c1c7cd !important;
}

.text-dark {
  color: #636f83 !important;
}

a.text-dark:hover,
a.text-dark:focus {
  color: #424a57 !important;
}

.text-body {
  color: #3c4b64 !important;
}

.text-muted {
  color: #768192 !important;
}

.c-dark-theme .text-muted {
  color: rgba(255, 255, 255, 0.6) !important;
}

.text-black-25 {
  color: rgba(0, 0, 0, 0.25) !important;
}

.text-black-50 {
  color: rgba(0, 0, 21, 0.5) !important;
}

.text-white-50 {
  color: rgba(255, 255, 255, 0.5) !important;
}

.text-hide {
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0;
  text-indent: 100%;
  white-space: nowrap;
  overflow: hidden;
}

.text-decoration-none {
  text-decoration: none !important;
}

.text-break {
  word-break: break-word !important;
  overflow-wrap: break-word !important;
}

.text-reset {
  color: inherit !important;
}

body {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
}

.font-xs {
  font-size: 0.75rem !important;
}

.font-sm {
  font-size: 0.85rem !important;
}

.font-lg {
  font-size: 1rem !important;
}

.font-xl {
  font-size: 1.25rem !important;
}

.font-2xl {
  font-size: 1.5rem !important;
}

.font-3xl {
  font-size: 1.75rem !important;
}

.font-4xl {
  font-size: 2rem !important;
}

.font-5xl {
  font-size: 2.5rem !important;
}

[class^='text-value'] {
  font-weight: 600;
}

.text-value-xs {
  font-size: 0.65625rem;
}

.text-value-sm {
  font-size: 0.74375rem;
}

.text-value {
  font-size: 0.875rem;
}

.text-value-lg {
  font-size: 1.3125rem;
}

.text-value-xl {
  font-size: 1.53125rem;
}

.text-white .text-muted {
  color: rgba(255, 255, 255, 0.6) !important;
}

.visible {
  visibility: visible !important;
}

.invisible {
  visibility: hidden !important;
}

/*# sourceMappingURL=utilities.css.map */
