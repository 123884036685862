@import '~antd/dist/antd.less';
@import 'common/less/variable.less';
@import 'common/less/antd-radio-v2.less';
@import 'common/less/utility.less';

.user-avatar {
  background-color: @primary300;
}

.c-wrapper {
  display: flex;
  flex: 1 1;
  flex-direction: column;
  min-width: 0;
  min-height: 100vh;
  transition: margin 0.3s;
}

.c-wrapper:not(.c-wrapper-fluid) .c-body {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

.c-main {
  flex-basis: auto;
  flex-shrink: 0;
  flex-grow: 1;
  min-width: 0;
  padding-top: 2rem;
  background-color: #fafafa;
}

//@media (min-width: 768px) {
//  .c-main > .container-fluid {
//    padding-right: 30px;
//    padding-left: 30px;
//  }
//}

.logo {
  height: 32px;
  margin: 16px;
  text-align: center;
  //background: rgba(255, 255, 255, 0.3);
}

.font-12 {
  font-size: 12px !important;
}

.font-14 {
  font-size: 14px !important;
}

.font-16 {
  font-size: 16px !important;
}

.ant-layout-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  background: #ffffff;
  height: 56px;
  position: sticky;
  top: 0;
  z-index: 10;
  width: 100%;
  padding: 0 20px 0 20px;
  border: 0;
  box-shadow: 0 2px 2px 0 rgba(60, 75, 100, 0.14), 0 3px 1px -2px rgba(60, 75, 100, 0.12),
    0 1px 5px 0 rgba(60, 75, 100, 0.2);
}

.site-layout .site-layout-background {
  background: #fff;
}

[data-theme='dark'] .site-layout .site-layout-background {
  background: #141414;
}

.sub-text {
  color: #94a3b8;
  font-size: 14px;
  line-height: 16px;
}

.text-gray {
  color: @black300;
  font-size: 16px;
  font-weight: 400;
}

.text-dark-gray {
  color: @black400;
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
}

.text-dark-gray02 {
  color: #595959;
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
}

.text-gray-14 {
  color: @black300;
  font-size: 14px;
  font-weight: 400;

  label {
    color: @black300;
    font-size: 14px;
    font-weight: 400;
  }
}

.text-blue-gray-14 {
  color: @black500;
  font-size: 14px;
  font-weight: 400;
}

.text-blue-gray-16 {
  color: @black500;
  font-size: 16px;
  font-weight: 400;
}

.label-gray {
  label {
    color: @black300;
    font-size: 16px;
    font-weight: 400;
  }
}

.box-gray {
  //border: 1px solid #94A3B8;
  border-radius: 16px;
  padding: 5px;
}

.employer-sub-title {
  font-size: 21px;
  font-weight: 400;
}

.notice-items-divider {
  width: 85%;
  float: right;
}

.blue-gray-color {
  color: @black900 !important;
}

.employer-qrcode-template-text {
  color: @black900;
  font-size: 13px;
}

.notice-panel {
  padding: 0 0 4px;
  //max-height: 250px;
  overflow: auto;

  li:first-child {
    padding-top: 8px;
    color: rgb(51, 51, 51);
    position: sticky;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 1000;
    background-color: white;
  }

  span {
    white-space: break-spaces;
    font-size: 14px;
  }

  a,
  a:focus,
  a:active {
    color: primary800;
  }

  a:hover {
    color: @primary600;
  }
}

.btn-link,
.btn-link:focus,
.btn-link:active {
  color: primary800;
}

.btn-link:hover {
  color: @primary600;
}

.notice-bell {
  .ant-badge-multiple-words {
    padding: 0 3px;
    background: #eb5757 !important;
  }
}

.attendance-select {
  .ant-select-selector {
    //border-radius: 6px !important;
  }
}

.employer-attendance-table {
  .ant-table-title {
    background: rgb(241, 245, 249);
    padding-left: 1rem !important;
    padding-bottom: 0.5rem !important;
    padding-top: 0.5rem !important;
  }
  .ant-table-thead > tr > th {
    height: 5px;
    padding: 8px !important;
    background: rgb(241, 245, 249);
    border-top: 1px solid #e9e9e9;
  }
}

.column-sticky {
  position: fixed;
  left: 360px !important;
}

.ant-table-summary {
  display: table-header-group;
}

.ant-pagination-item-link {
  display: flex !important;
  justify-content: center;
  align-items: center;
}

.custom-anticon {
  font-size: 16px;
  color: @black500;
}

.ant-tabs-large > .ant-tabs-nav .ant-tabs-tab {
  padding: 12px 0 !important;
}

.ant-notification,
.ant-modal-wrap,
.ant-modal-mask {
  z-index: 2000;
}

.ant-notification-notice {
  border-radius: 4px;
  padding: 14px 24px !important;
}

.ant-notification-notice-message {
  padding-top: 1px;
  margin-bottom: 0 !important;
}

// .ant-steps-icon {
//   display: inline-flex;
// }

.ant-form-item-label > label::after {
  display: none;
}

// .ant-form-item-label > label:not(.ant-form-item-required) {
//   margin-left: 9px;
// }

.ant-form-item {
  margin-bottom: 10px;
}

.custom-mobile-input {
  width: 100% !important;
  font-size: 14px;
  line-height: 1.5715;
  background-image: none !important;
  border: 1px solid #d9d9d9 !important;
  border-radius: 2px !important;
}

.custom-mobile-input:focus,
.custom-mobile-btn:focus {
  border-color: @primary300 !important;
  border-right-width: 1px !important;
  outline: 0;
  box-shadow: 0 0 0 2px rgb(14 131 100 / 20%);
}

.custom-mobile-input:hover,
.custom-mobile-btn:hover {
  border-color: @primary300 !important;
  border-right-width: 1px !important;
}

.xs-custom-upload-btn {
  .ant-upload-list-item {
    height: auto;

    .ant-upload-list-item-name {
      white-space: break-spaces;
    }
  }
}

.custom-upload-btn {
  display: inline-flex;

  .ant-upload-text-icon {
    display: none;
  }
}

.ant-table-cell {
  padding-top: 10px !important;
  padding-bottom: 10px !important;
}

.vertical-radio-group {
  .ant-radio-wrapper {
    display: flex;
    margin-right: 0;
    margin-bottom: 10px;
  }
}

.vertical-checkbox-group {
  .ant-checkbox-group-item {
    display: flex;
    margin-right: 0;
    margin-bottom: 10px;
  }
}

.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: #252525 !important;
}

.ant-tabs-tab-btn {
  outline: none;
  transition: all 0.3s;
  color: #94a3b8;
}

.ant-space {
  display: flex !important;
}

.scheduler-wrap-modal {
  left: auto;
  right: 30px;

  .ant-modal-content {
    box-shadow: 0 4px 28px rgba(0, 0, 0, 0.15);
    margin: 0 5px;
    border-radius: 8px;
  }
}

.scheduler {
  margin: 0 0 1rem !important;
  border-spacing: 0;
  .resource-table > tbody > tr > td {
    display: flex;
    align-items: center;
  }
}

.scheduler-view {
  div {
    div::-webkit-scrollbar {
      display: none; /* Safari and Chrome */
    }
  }
}

.ant-select:not(.ant-select-disabled):hover .ant-select-selector {
  border-color: @primary300 !important;
  border-right-width: 1px !important;
}

.ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
  color: rgba(0, 0, 0, 0.85);
  font-weight: 600;
  background-color: @primary300 !important;
}

.ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input) .ant-select-selector {
  border-color: @primary300 !important;
  box-shadow: 0 0 0 2px rgb(28 230 176 / 20%) !important;
  border-right-width: 1px !important;
  outline: 0;
}

.header3-text {
  font-size: 12px;
  font-weight: 500;
  background-color: #f1f5f9 !important;
}

.scheduler-type-select {
  min-width: 150px;

  .ant-select-selector {
    background: #f1f5f9 !important;
  }
}

.ant-table-thead > tr > th {
  background: #f1f5f9 !important;
}

.work-status-table {
  .ant-table-footer {
    background: #ffffff !important;
  }
}

.ant-pagination-item-ellipsis {
  display: flex !important;
}

.components-table-nested {
  .ant-table-tbody > tr > td > .ant-table-wrapper:only-child .ant-table {
    margin: -9px -5px -10px 30px !important;
  }
}

.unread-dot {
  .ant-badge-status-dot {
    width: 10px;
    height: 10px;
  }
}

.common-center-tabs,
.work-status-tabs {
  .ant-tabs-nav-wrap {
    justify-content: space-between !important;

    .ant-tabs-nav-list {
      width: 80%;

      .ant-tabs-tab {
        width: 50%;
        justify-content: center;
      }
    }
  }
}

.setting-fee-charge-tabs {
  .ant-tabs-nav-operations {
    display: none !important;
  }
}

.setting-config-tabs {
  .ant-tabs-nav-operations {
    display: none !important;
  }
}

.work-status-tabs {
  .ant-tabs-nav-operations {
    display: none !important;
  }
}
.work-status-sub-table {
  .ant-table-thead > tr > th {
    border-bottom: 2px solid @grey300;
  }

  .ant-table-summary > tr > td {
    border-bottom: 2px solid @grey300;
  }
}

.react-tel-input :disabled {
  color: rgba(0, 0, 0, 0.25);
  background: #f5f5f5 !important;
}

.table-icon-size {
  font-size: 18px;
  color: @black500;
}

.ant-mailingList-form-horizontal .ant-form-item {
  flex-direction: row !important;
  .ant-form-item-row {
    flex-direction: row !important;
  }
}

.login-page {
  .ant-image {
    display: block;
  }
}

.fee-charge-table {
  .ant-table-expanded-row > td {
    //  td > .ant-table-cell {
    padding-left: 0;
    //    padding-right: 0;
    //  }
    //
    .ant-table {
      margin-left: 0 !important;
    }
  }
}

.cycle-field {
  .ant-form-item-control-input:before {
    color: #ff4d4f;
    font-size: 14px;
    font-family: SimSun, sans-serif;
    content: '*';
    display: flex;
    align-self: self-start;
    margin-right: 4px;
  }

  .ant-form-item-explain-error {
    margin-left: 10px;
  }
}

.standard-act-btn-width {
  min-width: 125px;
}

.edit-endDate-modal {
  .ant-modal-body {
    .ant-form-item {
      .ant-form-item-row {
        justify-content: center;
      }
    }
  }
}

.show-mobile-field-error {
  border-color: #ff4d4f !important;
}

.public-holiday-table {
  .ant-table-pagination {
    display: none;
  }
}

.blacklist-national-table {
  .ant-table-pagination {
    display: none;
  }
}

.country-flag {
  height: 1.5rem;
  width: 1.5rem;
}

.country-dropdown {
  min-width: 110px !important;

  span {
    text-align: center;
  }
}

.ant-menu.ant-menu-dark {
  background-color: black;
}

.ant-menu-item-selected {
  background-color: @primary300;
}

.exclamation-circle-icon {
  fill: 'black';
  fill-opacity: 0.45;
}

.p-desc {
  color: rgba(0, 0, 0, 0.45);
}

.table-haeder {
  padding-top: 10px !important;
  padding-bottom: 10px !important;
  padding: 16px 16px;
  background: #f1f5f9;

  span {
    position: relative;
    color: rgba(0, 0, 0, 0.85);
    font-weight: 500;
    text-align: left;
  }
}

.auto-withdrawal-cycle-0 {
  padding-top: 14px !important;
  padding-bottom: 5px !important;
  padding: 16px 16px;
}

.auto-withdrawal-cycle-1 {
  padding-top: 5px !important;
  padding-bottom: 0px !important;
  padding: 16px 16px;
}

.auto-withdrawal-cycle-2 {
  padding-top: 0px !important;
  padding-bottom: 5px !important;
  padding: 16px 16px;
}

.import-upload-btn {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;

  button {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    vertical-align: middle;
  }
}

@text-selection-bg: #1890FF;